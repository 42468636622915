import Footer from "./components/Footer";
import Header from "./components/Header";
import CompanyGoal from "./sections/CompanyGoal";
import CompleteSolution from "./sections/CompleteSolution";
import Compounds from "./sections/Compounds";
import CoreValues from "./sections/CoreValues";
import DesignRequirements from "./sections/DesignRequirements";
import FreeMusicPricing from "./sections/FreeMusicPricing";
import HelmutHaase from "./sections/HelmutHaase";
// import Idea from "./sections/Idea";
import LandingPage from "./sections/LandingPage";
import MusicModule from "./sections/MusicModule";
// import OurBeginning from "./sections/OurBeginning";
import OurDrive from "./sections/OurDrive";
import SeasHelpers from "./sections/SeasHelpers";
// import Pricing from "./sections/Pricing";
import SubHeader from "./sections/SubHeader";
import UnderConstruction from "./sections/UnderConstruction";

function App() {
  return (
    <div>
      <Header />
      <SubHeader />
      <LandingPage />
      <MusicModule />
      <div className="divider mt-0 pt-0 dark:hidden"></div>
      {/* <Pricing /> */}
      <FreeMusicPricing />
      <CompleteSolution />
      <Compounds />
      <SeasHelpers />
      {/* <AutomaticEvents />
      <EasyPrint />
      <DirectCalendar /> */}
      {/* <Ratings /> */}
      <CoreValues />
      <div className="divider mt-0 pt-0 dark:hidden"></div>
      <DesignRequirements />
      <OurDrive />
      {/* <Idea />
      <OurBeginning />
      <div className="divider mt-0 pt-0 dark:hidden"></div> */}
      <CompanyGoal />
      <div className="divider mt-0 pt-0 dark:hidden"></div>
      <HelmutHaase />

      <UnderConstruction />
      <Footer />
    </div>
  );
}

export default App;
