import SectionWrapper from "../components/SectionWrapper";

export default function MusicModule() {
  return (
    <SectionWrapper className="py-12 lg:py-24 px-4 sm:px-6 lg:px-14">
      <div className="md:w-1/2 flex justify-center md:justify-end items-center">
        <table>
          <tbody className="flex flex-col md:just-table">
            <tr className="flex md:table-row gap-2 flex-col-reverse sm:flex-row mt-6 sm:mt-0">
              <td className="!p-0">
                <div className="w-full flex justify-center sm:justify-end">
                  <img
                    src={"/img/seas-screenshot-music-1.webp"}
                    alt={"seas music screenshot 1"}
                    className="w-full max-w-64"
                  />
                </div>
              </td>
              <td className="pl-2 xl:pl-8 pt-2 !pr-0">
                <div className="flex justify-center flex-col h-full">
                  <h2 className="font-semibold text-2xl">Musikeinteilung</h2>
                  <p>
                    Der gefüllte Plan: <br />
                    Selbsteinteilung in Harmonie
                  </p>
                </div>
              </td>
            </tr>
            <tr className="h-6"></tr>
            <tr className="flex md:table-row gap-2 flex-col-reverse sm:flex-row-reverse">
              <td className="!p-0">
                <div className="w-full flex justify-center sm:justify-end">
                  <img
                    src={"/img/seas-screenshot-music-2.webp"}
                    alt={"seas music screenshot 1"}
                    className="w-full max-w-64"
                  />
                </div>
              </td>
              <td className="pl-2 xl:pl-8 pt-2 !pr-0">
                <div className="flex justify-center flex-col h-full">
                  <h2 className="font-semibold text-2xl">Musik Journal</h2>
                  <p>
                    Der unverzichtbare Baustein für Dirigenten: <br />
                    Eure einfache und vollständige Dokumentation
                  </p>
                </div>
              </td>
            </tr>
            <tr className="h-6"></tr>
            <tr className="flex md:table-row gap-2 flex-col-reverse sm:flex-row mt-6 sm:mt-0">
              <td className="!p-0">
                <div className="w-full flex justify-center sm:justify-end">
                  <img
                    src={"/img/seas-screenshot-music-3.webp"}
                    alt={"seas music screenshot 3"}
                    className="w-full max-w-64"
                  />
                </div>
              </td>
              <td className=" pl-2 xl:pl-8 pt-2 !pr-0">
                <div className="flex justify-center flex-col h-full">
                  <h2 className="font-semibold text-2xl">Musik Reports</h2>
                  <p>
                    Moderne Reportfunktionen: <br />
                    Einfach, detailliert und ohne Kompromisse
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="divider divider-horizontal md:py-12 md:mr-6 md:ml-4 xl:mr-24 xl:ml-16 hidden md:flex"></div>
      <div className="md:w-1/2 flex gap-4 items-end sm:items-center justify-center md:justify-normal mt-12 md:mt-0">
        <div className="flex flex-col items-center -mr-10 sm:mr-0 -mb-4 sm:mb-0">
          <img
            src={"/img/cursor.svg"}
            alt={"cursor icon"}
            className="h-4 w-4 sm:h-8 sm:w-8"
          />
          <p className="-mt-1 text-xs sm:text-base">Per klick</p>
        </div>
        <div
          className="rounded-lg px-10 py-6"
          style={{ boxShadow: "0 1px 6px rgba(60,64,67,.3)" }}
        >
          <h2 className="font-semibold text-2xl">Notenliteratur</h2>

          <p className="mt-4">Glaubensspezifisch</p>
          <ul className="list-disc pl-4">
            <li>ev. Gesangbuch</li>
            <li>Gotteslob</li>
            <li>NAK Gesangbuch</li>
          </ul>

          <p className="mt-2">Gemeindespezifisch</p>
          <ul className="list-disc pl-4">
            <li>Gospel</li>
            <li>Motetten</li>
            <li>beliebig erweiterbar</li>
          </ul>

          <p className="text-center text-4xl text-seas-green mt-6">✓</p>
        </div>
      </div>
    </SectionWrapper>
  );
}
