import React from "react";

export default function OurDrive() {
  return (
    <div className="flex flex-col items-center bg-seas-medium" id="drive">
      <h1 id="idea" className="text-3xl font-semibold text-center mt-20">
        Unser Antrieb
      </h1>
      <div className="mt-6 max-w-4xl w-[88vw] text-lg flex flex-col gap-4 mb-20">
        <p className="text-justify">
          Engagierte Christen in der Gemeindearbeit haben es verdient, dass
          ihnen die besten Hilfsmittel zur Verfügung stehen, um ihren Aufgaben
          gerecht zu werden und sie administrativ zu entlasten.
        </p>
        <p className="text-justify">
          <strong>Selbsteinteilung</strong> bietet dem Einzelnen die{" "}
          <strong>größte Freiheit.</strong> Zufriedenheit und{" "}
          <strong>Wertschätzung</strong> verbessern das{" "}
          <strong>Wohlfühlen</strong> und{" "}
          <strong>steigern die Motivation.</strong> Gemeinden erzielen mit
          unserer App einen <strong>Automatisierungsgrad</strong> von mehr als{" "}
          <strong>95%.</strong>
        </p>
        <p className="text-justify">
          Sollten Gemeinden konzeptionelle Bedenken haben, können sie die App
          auch in bewährter Art und Weise nutzen.
        </p>
        <p className="mt-2">
          ~ Mit ❤️ programmiert von Bastian Biedermann • CTO - SEAS
          Kirchengemeinde
        </p>
      </div>
    </div>
  );
}
