import React from "react";

import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Imprint() {
  return (
    <div>
      <Header />

      <div className="px-8 max-w-6xl w-full mx-auto my-24">
        <h1 className="text-center font-bold text-5xl text-black/80 dark:text-white">
          Impressum
        </h1>

        <h2 className="mt-6 text-2xl">SEAS-Kirchengemeinde UG</h2>
        <h2 className="text-2xl">(haftungsbeschränkt)</h2>

        <p className="mt-6">Am Hohlweg 17</p>
        <p>58256 Ennepetal</p>
        <p>Deutschland</p>

        <h2 className="mt-6 text-2xl">Geschäftsführender Gesellschafter</h2>
        <p>Helmut Haase</p>
        <h2 className="mt-6 text-2xl">Handelsregister</h2>
        <p>HRB 12989</p>
        <p>Amtsgericht Hagen</p>

        <h2 className="mt-6 text-2xl">Kontakt</h2>
        <p>E-Mail-Adresse:</p>
        <a
          className="text-green-700"
          href="mailto:info@seas-kirchengemeinde.de"
        >
          info@seas-kirchengemeinde.de
        </a>

        <p className="mt-3">Telefon:</p>
        <p>+49 177 3764645</p>

        <h2 className="mt-6 text-2xl">Kontoverbindung</h2>
        <p>Kontoinhaber: Seas-Kirchengemeinde UG (haftungsbeschränkt)</p>
        <p>IBAN: DE67 1101 0100 2038 6082 75</p>
        <p>BIC: SOBKDEBBXXX</p>
        <p>Bank: Solaris Bank</p>

        <h2 className="mt-6 text-2xl">Online-Streitbeilegung</h2>
        <p className="text-lg">
          Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS)
          für Verbraucher{" "}
          <a
            className="text-green-700"
            href="https://ec.europa.eu/consumers/odr"
          >
            https://ec.europa.eu/consumers/odr
          </a>
        </p>
        <p className="text-lg">
          Wir sind nicht verpflichtet, an einem Streitbeteiligungsverfahren vor
          einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
      <Footer />
    </div>
  );
}
