import React from "react";

export default function Compounds() {
  return (
    <div className="flex items-center flex-wrap flex-col px-4 md:px-8 mb-12 mt-12 md:mt-24">
      <div className="max-w-xl">
        <h2 className="text-2xl sm:text-4xl font-semibold text-center">
          Gemeindeverbünde
        </h2>
        <p className="mt-4 text-justify">
          Gemeindeverbünde bieten den christlichen Kirchen die Chance,
          innovative Ansätze in der Seelsorge und in der Gemeindearbeit zu
          entwickeln, um den Bedürfnissen der heutigen Gesellschaft gerecht zu
          werden und Ressourcen übergreifend zu nutzen.
        </p>
      </div>
      <div className="mt-6 flex gap-4 items-center">
        <div className="border rounded-lg shadow-lg w-52 px-6 py-4">
          <h2 className="text-lg font-semibold w-full">
            <span className="font-bold text-seas-green text-center">SEAS</span>{" "}
            grenzenlos
          </h2>
          <p className="mt-2 text-center">
            gemeinsam stark <br /> auch für Gemeindeverbünde
          </p>
        </div>
        <p className="text-4xl text-seas-green hidden sm:block">✓</p>
      </div>
    </div>
  );
}
