import React, { useCallback, useState } from "react";

import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Contact() {
  const [issue, setIssue] = useState("product");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("music");
  const [plz, setPLZ] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("ev");
  const [comName, setComName] = useState("");

  const [sentSuccessful, setSendSuccessful] = useState(undefined);

  const submitForm = useCallback(() => {
    const form = document.getElementById("form");
    setSendSuccessful(undefined);

    const valid = form.reportValidity();
    if (!valid) return;

    let sendProduct = product;
    if (issue !== "product") sendProduct = "";

    let sendType = type;
    if (issue !== "product") sendType = "";

    fetch("https://api.seas-kirchengemeinde.de/web/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        issue,
        name,
        email,
        product: sendProduct,
        plz,
        type: sendType,
        comName,
        message,
      }),
    }).then((res) => {
      res
        .json()
        .then((json) => {
          form.reset();
          setSendSuccessful(json.success);
        })
        .catch(() => {
          setSendSuccessful(false);
        });
    });
  }, [product, issue, name, email, plz, type, comName, message]);

  return (
    <div>
      <Header />

      <div className="px-8 max-w-2xl w-full mx-auto my-24 flex flex-col gap-3">
        <a href="/">
          <button className="text-lg">← Zurück zur Startseite</button>
        </a>
        <h1 className="text-3xl font-semibold">Kontakt</h1>
        <form
          className="rounded-lg shadow-xl bg-seas-medium-light flex flex-col px-8 py-6"
          id="form"
        >
          <h2 className="text-center text-2xl font-semibold mb-4">
            Senden Sie uns eine Nachricht
          </h2>

          <span>Ihr Anliegen</span>
          <select
            className="select"
            required
            name="issue"
            onChange={(e) => setIssue(e.target.value)}
          >
            <option value="product">Kostenlose Bestellung</option>
            <option value="bug">Fehler melden</option>
            <option value="other">Etwas anderes</option>
          </select>

          {issue === "product" && (
            <>
              <span>Produkt auswählen</span>
              <select
                className="select"
                required={issue === "product"}
                name="product"
                onChange={(e) => setProduct(e.target.value)}
              >
                <option value="music">Musik Modul</option>
              </select>
            </>
          )}
          <span className="mt-2">Ihr Name*</span>
          <input
            type="text"
            className="input"
            placeholder="Max Mustermann"
            required
            name="name"
            onChange={(e) => setName(e.target.value)}
          />

          <span className="mt-2">Ihre E-Mail Adresse*</span>
          <input
            type="email"
            className="input"
            placeholder="max@seas-kirchengemeinde.de"
            required
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {issue === "product" && (
            <>
              <span className="mt-2">Angeben zu Ihrer Gemeinde:</span>
              <div className="flex gap-2 mt-1 flex-wrap">
                <div className="flex flex-col w-[6.5rem]">
                  <span>Glauben*</span>
                  <select
                    className="select"
                    required={issue === "product"}
                    name="type"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="ev">ev.</option>
                    <option value="kath">kath.</option>
                    <option value="nak">NAK</option>
                    <option value="other">andere</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <span className="">PLZ*</span>
                  <input
                    type="number"
                    className="input w-20"
                    placeholder="58119"
                    required={issue === "product"}
                    name="plz"
                    onChange={(e) => setPLZ(e.target.value)}
                  />
                </div>
                <div className="flex flex-col flex-grow">
                  <span>Gemeindename*</span>
                  <input
                    type="text"
                    className="input w-full"
                    placeholder="Hagen Hohenlimburg"
                    required={issue === "product"}
                    name="comname"
                    onChange={(e) => setComName(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <span className="mt-2">Ihre Nachricht</span>
          <textarea
            name="message"
            className="textarea resize-none"
            id="message"
            rows={4}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

          {sentSuccessful === true && (
            <p className="text-seas-green">Nachricht erfolgreich gesendet.</p>
          )}
          {sentSuccessful === false && (
            <p className="text-red-500">
              Es gab einen Fehler beim versenden der Nachricht. Bitte versuchen
              Sie es später erneut oder schreiben Sie uns direkt eine E-Mail.
            </p>
          )}

          <div className="mt-6">
            <button
              className="btn bg-seas-green text-white hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              Absenden
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
