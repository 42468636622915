import React from "react";

import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Conditions() {
  return (
    <div>
      <Header />

      <div className="px-8 max-w-6xl w-full mx-auto my-24 flex flex-col gap-3">
        <h1 className="text-center font-bold text-5xl text-black/80 dark:text-white">
          AGB
        </h1>

        <h1 className="font-bold mt-3">Allgemeine Geschäftsbedingungen</h1>

        <h2 className="font-semibold">
          § 1. Sachlicher Geltungsbereich und Vertragsgegenstand
        </h2>
        <p>
          Die nachstehenden Bedingungen (die „Allgemeinen Vertragsbedingungen“)
          gelten für die Nutzung und Unterstützung von
          Standard-Softwareprogrammen (die „Software“), die von der SEAS
          Kirchengemeinde UG nachfolgend SEAS genannt, hergestellt und als
          Software über das Internet bereitgestellt werden.
        </p>
        <p>Gegenstand des Vertrages sind:</p>

        <ul className="list-disc pl-8">
          <li>
            die Überlassung des Softwareprogramms seas-kirchengemeinde zur
            Nutzung von Selbsteinteilungs-Plänen und Administration für die
            Kirchengemeinde, nachfolgend Kunde genannt, beziehbar über das
            Internet-Portal der SEAS{" "}
            <a
              className="text-green-700"
              href="https://seas-kirchengemeinde.de"
            >
              https://seas-kirchengemeinde.de
            </a>
          </li>
          <li>
            die Speicherung von Daten des Kunden auf Servern der
            Serviceplattform.
          </li>
        </ul>

        <h2 className="font-semibold">§ 2. Art und Umfang der Leistungen</h2>
        <p>
          Art und Umfang der beiderseitigen Leistungen werden durch die
          vertraglichen Abmachungen geregelt. Der im Vertrag definierte
          Leistungsumfang gilt als vereinbarte Beschaffenheit. Maßgebend dafür
          sind:
        </p>

        <ul className="list-disc pl-8">
          <li>
            der im Vertrag definierte Leistungsumfang der aufgeführten Software,
          </li>
          <li>die jeweilige Benutzerdokumentation,</li>
          <li>die Eignung für die im Vertrag vorausgesetzte Verwendung,</li>
          <li>die im Vertrag festgelegten Bedingungen,</li>
          <li>die nachstehenden Bedingungen,</li>
          <li>
            allgemein angewandte technische Richtlinien und Fachnormen,
            insbesondere auch die internationalen Standards und Vorschläge der
            Internet Engineering Task Force (IETF), wie sie in den
            Request-for-Comments (RFC) dokumentiert sind, und des W3C (World
            Wide Web Consortium).
          </li>
        </ul>

        <p>Es gelten ausschließlich die AGB der SEAS.</p>

        <h2 className="font-semibold">§ 3. Nutzungsbedingungen</h2>
        <h2 className="font-semibold">
          3.1. Rechte des Kunden an der Software
        </h2>

        <p>
          SEAS räumt dem Kunden für die Dauer des Vertrages ein nicht
          ausschließliches, nicht übertragbares und nicht unterlizenzierbares
          Recht zur Nutzung der im Vertrag genannten Software und der
          zugehörigen Anwenderdokumentation ein. Die Bereitstellung der Software
          erfolgt über das Internet innerhalb von 7 Kalendertagen, in der Regel
          früher. Übergabepunkt für die Leistungen ist der Router-Ausgang des
          von SEAS genutzten Serviceproviders zum Internet. Der Kunde
          verpflichtet sich, die Software ausschließlich vertragsgemäß zu nutzen
          und weder an Dritte weiterzugeben noch sie in sonstiger Art und Weise
          Dritten zugänglich zu machen. Der Kunde ist nicht berechtigt, die
          Software zu „reverse engineeren“, zu dekompilieren, zu
          disassemblieren, zu vervielfältigen oder jeglichen Teil der Software
          zu benutzen, um eigene Anwendungen zu erstellen.
        </p>

        <p>
          Der Kunde erkennt hiermit SEAS als alleinigen Lizenzgeber der Software
          an.
        </p>

        <p>
          Der Kunde erkennt hiermit Marke, Name und Patentrechte in Bezug auf
          die Software und die zugehörige Dokumentation von SEAS an. Der Kunde
          darf Copyright-Informationen oder sonstige ähnliche Eigentumshinweise
          in den Programmen und der zugehörigen Dokumentation weder entfernen,
          noch ändern oder anderweitig modifizieren.
        </p>

        <h2 className="font-semibold">3.2. Rechte des Kunden an den Daten</h2>
        <p>
          Die durch die Software erfassten, verarbeiteten und erzeugten Daten
          werden auf den Servern der von uns beauftragten Serviceplattform
          gespeichert. Der Kunde bleibt in jedem Fall Alleinberechtigter an den
          Daten.
        </p>

        <h2 className="font-semibold">
          3.3. Zuwiderhandlung gegen die Nutzungsbedingungen
        </h2>
        <p>
          Bei Zuwiderhandlungen gegen die genannten Nutzungsbedingungen ist SEAS
          berechtigt, den Vertrag ganz oder teilweise fristlos zu kündigen. SEAS
          behält sich in diesem Fall zusätzlich die Geltendmachung der sich aus
          der vertragswidrigen Handlung ergebenden Schadensersatzansprüche gegen
          den Kunden vor.
        </p>

        <h2 className="font-semibold">3.4. Vertragsdauer und Kündigung</h2>
        <p>
          Der Vertrag gilt ab dem Zeitpunkt des Abschlusses der Bestellung im
          Webshop („Jetzt abschließen“) oder der Unterschrift.
        </p>
        <p>
          SEAS ist berechtigt, den Vertrag aus wichtigem Grund mit einer Frist
          von 30 Kalendertagen zu kündigen, insbesondere bei fehlgeschlagenen
          Lastschriften oder Kreditkarteneinzügen.
        </p>

        <h2 className="font-semibold">3.5. Widerrufsrecht</h2>
        <p>
          Der Kunde hat nach §355 und §312g BGB ab Eingang der
          Vertragsunterlagen ein 14-tägiges Widerrufsrecht ohne Angabe von
          Gründen. Der Widerruf ist SEAS schriftlich per E-Mail an{" "}
          <a
            className="text-green-700"
            href="mailto:info@seas-
          kirchengemeinde.de"
          >
            info@seas-kirchengemeinde.de
          </a>{" "}
          oder über den Webshop mitzuteilen.
        </p>

        <h2 className="font-semibold">
          § 4. Wartungsbedingungen und Service Level
        </h2>
        <h2 className="font-semibold">
          4.1. Weiterentwicklungen/Leistungsänderung
        </h2>
        <p>
          SEAS behält sich im Zuge des technischen Fortschritts und einer
          Leistungsoptimierung nach Vertragsschluss Weiterentwicklungen und
          Leistungsänderungen (z.B. durch Verwendung neuerer oder anderer
          Technologien, Systeme, Verfahren oder Standards) vor. Bei wesentlichen
          Leistungsänderungen wird rechtzeitig eine entsprechende Mitteilung von
          SEAS an den Kunden erfolgen. Entstehen für den Kunden durch die
          Leistungsänderungen wesentliche Nachteile, so steht diesem das Recht
          zur außerordentlichen Kündigung des Vertrages zum Änderungstermin zu.
          Die Kündigung muss durch den Kunden innerhalb von 14 Tagen nach Erhalt
          der Mitteilung über die Leistungsänderung erfolgen.
        </p>
        <p>
          Bei Bereitstellung neuer Versionen der Software räumt SEAS dem Kunden
          die in Abschnitt 3 aufgeführten Rechte entsprechend auch für die
          jeweilige neue Version ein.
        </p>

        <h2 className="font-semibold">4.2. Systembetrieb</h2>
        <p>
          SEAS stellt sicher, dass die bereitgestellte Software in für die
          Anforderungen des Kunden geeigneter Umgebung und Ausprägung sowie auf
          für den Verwendungszweck des Kunden geeigneter Hardware betrieben
          wird. Hierzu zählen Anzahl und Art der Server, regelmäßige Backups,
          Skalierbarkeit, Stromversorgung, Klimatechnik, Firewalling,
          Viruschecking, breitbandige Internetanbindung.
        </p>
        <p>
          SEAS führt tägliche Backups der Datenbestände durch. Über eine
          Wiederherstellungsprozedur kann SEAS die Rücksicherung der Kundendaten
          auf ausdrücklichen Wunsch des Kunden durchführen. Die Kosten für diese
          Dienstleistung werden dem Kunden in Rechnung gestellt.
        </p>

        <h2 className="font-semibold">4.3. Systemverfügbarkeit</h2>
        <p>
          Die Verfügbarkeit des Netzwerks der Serviceplattform am Router-Ausgang
          im Internet beträgt mindestens 99% im Jahresmittel. Die Ausfallzeit
          wird in vollen Minuten ermittelt und errechnet sich aus der Summe der
          Entstörungszeiten pro Jahr. Hiervon ausgenommen sind diejenigen
          Zeiträume, die SEAS als sogenannte Wartungsfenster zur Optimierung und
          Leistungssteigerung kennzeichnet sowie Zeitverlust bei der
          Störungsbeseitigung durch Gründe, die nicht durch SEAS zu vertreten
          sind und Ausfälle aufgrund höherer Gewalt.
        </p>

        <h2 className="font-semibold">
          4.4. Störungen der Systemverfügbarkeit
        </h2>
        <p>
          Störungen der Systemverfügbarkeit müssen vom Kunden unverzüglich nach
          Bekanntwerden gemeldet werden. Bei Störungsmeldungen beginnt die
          Entstörung innerhalb von 24 Stunden. Verzögerungen der Entstörung, die
          vom Kunden zu vertreten sind (z.B. durch Nichtverfügbarkeit eines
          Ansprechpartners auf Kundenseite), werden nicht auf die
          Entstörungszeit angerechnet.
        </p>

        <h2 className="font-semibold">§ 5. Gewährleistung</h2>

        <p>
          Es ist nach dem Stand der Technik nicht möglich, Fehler in Software A
          auszuschließen. SEAS gewährleistet jedoch, dass die unter{" "}
          <a
            className="text-green-700"
            href="mailto:info@seas-
          kirchengemeinde.de"
          >
            info@seas-kirchengemeinde.de
          </a>{" "}
          genannte Software grundsätzlich einsetzbar ist. Die Verjährungsfrist
          beträgt ein Jahr.
        </p>
        <p>
          Fehler in der Software und der zugehörigen Dokumentation werden
          innerhalb angemessener Frist unentgeltlich von SEAS beseitigt.
          Voraussetzung für diesen Fehlerbeseitigungsanspruch ist, dass der
          Fehler reproduzierbar ist. SEAS kann zur Erfüllung der
          Gewährleistungspflicht nach eigener Wahl entweder nachbessern oder
          Ersatz liefern. Insbesondere kann SEAS zur Erfüllung der
          Gewährleistungspflicht dem Kunden eine neue Version der Software zur
          Verfügung stellen. Einer Fehlerbeseitigung steht es gleich, wenn SEAS
          eine alternative Lösung zur fehlerhaften Funktion liefert, die dem
          Kunden die vertragsgemäße Nutzung erlaubt.
        </p>
        <p>
          Die Gewährleistungsansprüche sind ausgeschlossen, wenn die Software
          nicht vertragsgemäß eingesetzt wird. Des Weiteren sind die
          Gewährleistungsansprüche ausgeschlossen, wenn der Kunde Änderungen
          oder Erweiterungen an der im Vertrag genannten Software durchführt
          Erweiterungen stehen.
        </p>
        <p>
          Wird ein wesentlicher Programmfehler nicht entsprechend den genannten
          Bedingungen von SEAS behoben, kann der Kunde die Minderung der
          monatlichen Gebühr verlangen. Das gleiche Recht hat SEAS, wenn die
          Herstellung der Fehlerkorrektur mit angemessenem Aufwand nicht möglich
          ist.
        </p>

        <h2 className="font-semibold">§ 6. Haftungsbeschränkung</h2>
        <p>Die Haftung für entgangenen Gewinn ist ausgeschlossen.</p>
        <p>
          Für Störungen auf Telekommunikationsverbindungen, für Störungen auf
          Leitungswegen innerhalb des Internets, bei höherer Gewalt, bei
          Verschulden Dritter oder des Kunden selbst wird von SEAS keine Haftung
          übernommen. Für Schäden, die entstehen, wenn der Kunde Passwörter oder
          Benutzerkennungen an Nichtberechtigte weitergibt, übernimmt SEAS keine
          Haftung.
        </p>
        <p>
          Für Datenverlust aufgrund von Hackerangriffen trotz angemessener
          Schutzmaßnahmen übernimmt SEAS keine Haftung.
        </p>

        <h2 className="font-semibold">§ 7. Vergütung</h2>
        <p>
          Für die Leistungen wird eine im Vertrag vereinbarte monatliche bzw.
          jährliche Gebühr laut der jeweils aktuellen und veröffentlichen
          Preisliste fällig. Die Beiträge werden zu Beginn in Rechnung gestellt
          und über das vom Kunden gewählte Zahlungsmittel abgewickelt.
        </p>

        <h2 className="font-semibold">§ 8. Zahlungsbedingungen</h2>
        <p>
          Zahlungen erfolgen über das vom Kunden bei der Bestellung gewählte
          Zahlungsmittel. Kunden werden über die zur Verfügung stehenden
          Zahlungsmittel auf einer gesonderten Informationsseite unterrichtet
        </p>
        <p>
          Der Kunde ist nicht berechtigt, gegenüber SEAS mit Forderungen
          aufzurechnen, es sei denn, dass es sich um rechtskräftig festgestellte
          Ansprüche oder von SEAS schriftlich anerkannte Ansprüche handelt.
        </p>

        <h2 className="font-semibold">§ 9. Speicherung des Vertragstextes</h2>
        <p>
          Der Kunde kann den Vertragstext vor der Abgabe der Bestellung
          ausdrucken, indem er die AGB Seite über die Druckfunktion seines
          Browsers ausdruckt.
        </p>
        <p>
          Der Verkäufer sendet dem Kunden außerdem eine Bestellbestätigung mit
          allen Bestelldaten an die von ihm angegebene E-Mail-Adresse zu. Mit
          der Bestellbestätigung erhält der Kunde ferner eine Kopie der AGB
          nebst Widerrufsbelehrung.
        </p>

        <h2 className="font-semibold">§ 10. Vertraulichkeit, Datenschutz</h2>
        <p>
          Die Vertragsparteien verpflichten sich, die im Rahmen des
          Vertragsgegenstandes gewonnenen Erkenntnisse – insbesondere technische
          oder wirtschaftliche Daten sowie sonstige Kenntnisse – geheim zu
          halten und sie ausschließlich für die Zwecke des Gegenstands des
          Vertrages zu verwenden.
        </p>
        <p>
          Dies gilt nicht für Informationen, die öffentlich zugänglich sind oder
          ohne unberechtigtes Zutun oder Unterlassen der Vertragsparteien
          öffentlich zugänglich werden oder aufgrund richterlicher Anordnung
          oder eines Gesetzes zugänglich gemacht werden müssen.
        </p>
        <p>
          Im Falle von Supportunterstützung bei Problemen des Kunden kann es
          notwendig werden auf Datensätze des Kunden zuzugreifen. Der Zugriff
          kann über ein Webmeeting mit dem Kunden erfolgen oder per
          Datenbankanalyse. Dieser Zugriff ist auf den Zeitraum der jeweiligen
          Supportmaßnahme begrenzt.
        </p>
        <p>
          Sofern im Rahmen des Vertragsgegenstandes personenbezogene Daten
          verarbeitet werden müssen, werden SEAS und der Kunde die gesetzlichen
          Datenschutzbestimmungen einhalten.
        </p>
        <p>
          SEAS weist den Kunden gemäß Bundesdatenschutzgesetz (BDSG) darauf hin,
          dass Daten des Kunden gespeichert werden.
        </p>
        <p>
          Weiterhin gelten die Datenschutzbestimmungen von SEAS (
          <a
            className="text-green-700"
            href="https://seas-kirchengemeinde.de/privacy"
          >
            https://seas-kirchengemeinde.de/privacy
          </a>
          ).
        </p>

        <h2 className="font-semibold">§ 11. Schlussbestimmungen</h2>
        <p>
          Erfüllungsort ist Ennepetal in NRW. Der Gerichtsstand für beide
          Vertragsparteien ist das Amtsgericht Schwelm. Es gilt ausschließlich
          das Recht der Bundesrepublik Deutschland. Der Vertrag, seine
          Ergänzungen und Änderungen sowie Änderungen der Form bedürfen der
          Schriftform.
        </p>

        <h2 className="font-semibold">§ 12. Salvatorische Klausel</h2>
        <p>
          Sollte eine Bestimmung des Vertrags unwirksam sein oder werden, so
          berührt dies die Gültigkeit des Vertrags im Übrigen nicht. Die
          Vertragspartner verpflichten sich, die unwirksame Bestimmung durch
          eine solche zu ersetzen, die dem wirtschaftlich Gewollten am nächsten
          kommt. Gleiches gilt, wenn eine Vertragslücke offenbar werden sollte.
        </p>

        <p>Stand: April 2024</p>
      </div>
      <Footer />
    </div>
  );
}
