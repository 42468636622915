import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="flex flex-col items-center bg-black/85 px-4 md:px-8 print:bg-white decoration-gray-400 text-gray-100 print:decoration-black print:text-black">
      <p className="my-8 text-center">
        &copy; (copyright) Helmut Haase 2022 - {new Date().getFullYear()} • SEAS
        Kirchengemeinde UG (haftungsbeschränkt)
      </p>
      <div className="my-4 flex gap-[30vw]">
        <div className="flex flex-col gap-0.5 ">
          <Link className="text-sm underline" to="/imprint">
            Impressum
          </Link>
          <Link className="text-sm underline" to="/conditions">
            AGB
          </Link>
          <Link className="text-sm underline" to="/privacy">
            Datenschutz
          </Link>
        </div>
        <div className="flex flex-col gap-0.5">
          <a className="text-sm underline" href="/">
            Kontakt
          </a>
          <a className="text-sm underline" href="/">
            Weitere Projekte
          </a>
          <a
            className="text-sm underline"
            href="https://status.seas-kirchengemeinde.de"
          >
            Server Status
          </a>
        </div>
      </div>
      <p className="my-8 text-xs">
        Design &amp; Idee • Helmut Haase • Mit ❤️ umgesetzt von Bastian Biedermann
      </p>
    </footer>
  );
}
