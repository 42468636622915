import React from "react";

import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Privacy() {
  return (
    <div>
      <Header />

      <div className="px-8 max-w-6xl w-full mx-auto my-24 flex flex-col gap-3">
        <h1 className="text-center font-bold text-5xl text-black/80 dark:text-white">
          Datenschutzerklärung
        </h1>

        <p className="mt-3">
          Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
          Datenschutz hat einen besonders hohen Stellenwert für die
          Geschäftsführung der SEAS Kirchengemeinde UG (haftungsbeschränkt), im
          folgenden SEAS genannt, ist. Eine Nutzung der Internetseiten der SEAS
          ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich.
          Sofern eine betroffene Person besondere Services unseres Unternehmens
          über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch
          eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die
          Verarbeitung personenbezogener Daten erforderlich und besteht für eine
          solche Verarbeitung keine gesetzliche Grundlage, holen wir generell
          eine Einwilligung der betroffenen Person ein.
        </p>
        <p>
          Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
          der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
          Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
          und in Übereinstimmung mit den für die SEAS geltenden
          landesspezifischen Datenschutzbestimmungen. Mittels dieser
          Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über
          Art, Umfang und Zweck der von uns erhobenen, genutzten und
          verarbeiteten personenbezogenen Daten informieren. Ferner werden
          betroffene Personen mittels dieser Datenschutzerklärung über die ihnen
          zustehenden Rechte aufgeklärt.
        </p>
        <p>
          Die SEAS hat als für die Verarbeitung Verantwortlicher zahlreiche
          technische und organisatorische Maßnahmen umgesetzt, um einen
          möglichst lückenlosen Schutz der über diese Internetseite
          verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
          Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
          aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden
          kann. Aus diesem Grund steht es jeder betroffenen Person frei,
          personenbezogene Daten auch auf alternativen Wegen, beispielsweise
          telefonisch, an uns zu übermitteln.
        </p>

        <h2 className="font-semibold">1. Begriffsbestimmungen</h2>
        <p>
          Die Datenschutzerklärung der SEAS beruht auf den Begrifflichkeiten,
          die durch den Europäischen Richtlinien- und Verordnungsgeber beim
          Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden.
          Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als
          auch für unsere Kunden und Geschäftspartner einfach lesbar und
          verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
          verwendeten Begrifflichkeiten erläutern.
        </p>
        <p>
          Wir verwenden in dieser Datenschutzerklärung unter anderem die
          folgenden Begriffe:
        </p>

        <h2 className="font-semibold">a) personenbezogene Daten</h2>
        <p>
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          „betroffene Person“) beziehen. Als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
          oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
          physiologischen, genetischen, psychischen, wirtschaftlichen,
          kulturellen oder sozialen Identität dieser natürlichen Person sind,
          identifiziert werden kann.
        </p>

        <h2 className="font-semibold">b) betroffene Person</h2>
        <p>
          Betroffene Person ist jede identifizierte oder identifizierbare
          natürliche Person, deren personenbezogene Daten von dem für die
          Verarbeitung Verantwortlichen verarbeitet werden.
        </p>

        <h2 className="font-semibold">c) Verarbeitung</h2>
        <p>
          Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
          ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
          personenbezogenen Daten wie das Erheben, das Erfassen, die
          Organisation, das Ordnen, die Speicherung, die Anpassung oder
          Veränderung, das Auslesen, das Abfragen, die Verwendung, die
          Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
          Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
          das Löschen oder die Vernichtung.
        </p>

        <h2 className="font-semibold">d) Einschränkung der Verarbeitung</h2>
        <p>
          Einschränkung der Verarbeitung ist die Markierung gespeicherter
          personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
          einzuschränken.
        </p>

        <h2 className="font-semibold">e) Profiling</h2>
        <p>
          Profiling ist jede Art der automatisierten Verarbeitung
          personenbezogener Daten, die darin besteht, dass diese
          personenbezogenen Daten verwendet werden, um bestimmte persönliche
          Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
          insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher
          Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit,
          Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person
          zu analysieren oder vorherzusagen.
        </p>

        <h2 className="font-semibold">f) Pseudonymisierung</h2>
        <p>
          Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
          einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung
          zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
          Person zugeordnet werden können, sofern diese zusätzlichen
          Informationen gesondert aufbewahrt werden und technischen und
          organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
          personenbezogenen Daten nicht einer identifizierten oder
          identifizierbaren natürlichen Person zugewiesen werden.
        </p>

        <h2 className="font-semibold">
          g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
        </h2>
        <p>
          Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die
          natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind
          die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
          das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche
          beziehungsweise können die bestimmten Kriterien seiner Benennung nach
          dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
        </p>

        <h2 className="font-semibold">h) Auftragsverarbeiter</h2>
        <p>
          Auftragsverarbeiter ist eine natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
          Auftrag des Verantwortlichen verarbeitet.
        </p>

        <h2 className="font-semibold">i) Empfänger</h2>
        <p>
          Empfänger ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt
          werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt
          oder nicht. Behörden, die im Rahmen eines bestimmten
          Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
          Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
          jedoch nicht als Empfänger.
        </p>

        <h2 className="font-semibold">j) Dritter</h2>
        <p>
          Dritter ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle außer der betroffenen Person, dem
          Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
          der unmittelbaren Verantwortung des Verantwortlichen oder des
          Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
          verarbeiten.
        </p>

        <h2 className="font-semibold">k) Einwilligung</h2>
        <p>
          Einwilligung ist jede von der betroffenen Person freiwillig für den
          bestimmten Fall in informierter Weise und unmissverständlich
          abgegebene Willensbekundung in Form einer Erklärung oder einer
          sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
          Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
          betreffenden personenbezogenen Daten einverstanden ist.
        </p>

        <h2 className="font-semibold">
          2. Name und Anschrift des für die Verarbeitung Verantwortlichen
        </h2>
        <p>
          Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger
          in den Mitgliedstaaten der Europäischen Union geltenden
          Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
          Charakter ist die:
        </p>
        <p>
          SEAS Kirchengemeinde UG (haftungsbeschränkt) <br /> Am Hohlweg 17{" "}
          <br /> 58256 Ennepetal <br /> Deutschland
        </p>
        <p>E-Mail: info@seas-kirchengemeinde.de</p>

        <h2 className="font-semibold">3. Rechtsgrundlage der Verarbeitung</h2>
        <p>
          Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
          unserer Datenverarbeitungen mit. Art. 6 I lit. a DSGVO dient unserem
          Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen
          wir eine Einwilligung für einen bestimmten Verarbeitungszweck
          einholen. Ist die Verarbeitung personenbezogener Daten zur Erfüllung
          eines Vertrags, dessen Vertragspartei die betroffene Person ist,
          erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der
          Fall ist, die für eine Lieferung von Waren oder die Erbringung einer
          sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die
          Verarbeitung auf Art. 6 I lit. b DSGVO. Gleiches gilt für solche
          Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen
          erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten
          oder Leistungen. Unterliegt unser Unternehmen einer rechtlichen
          Verpflichtung durch welche eine Verarbeitung von personenbezogenen
          Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
          Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DSGVO. In
          seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten
          erforderlich werden, um lebenswichtige Interessen der betroffenen
          Person oder einer anderen natürlichen Person zu schützen. Dies wäre
          beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt
          werden würde und daraufhin sein Name, sein Alter, seine
          Krankenkassendaten oder sonstige lebenswichtige Informationen an einen
          Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden
          müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DSGVO
          beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f
          DSGVO beruhen. Auf dieser Rechtsgrundlage basieren
          Verarbeitungsvorgänge, die von keiner der vorgenannten
          Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung
          eines berechtigten Interesses unseres Unternehmens oder eines Dritten
          erforderlich ist, sofern die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen nicht überwiegen. Solche
          Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil
          sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er
          vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
          anzunehmen sein könnte, wenn die betroffene Person ein Kunde des
          Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DSGVO).
        </p>

        <h2 className="font-semibold">4. Arten der verarbeiteten Daten</h2>
        <ul className="list-disc pl-8">
          <li>Bestandsdaten (z.B., Namen, Adressen)</li>
          <li>Kontaktdaten (z.B., E-Mail, Telefonnummern)</li>
          <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos)</li>
          <li>
            Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten)
          </li>
          <li>
            Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen)
          </li>
        </ul>

        <h2 className="font-semibold">5. Kategorien betroffener Personen</h2>
        <p>
          Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir
          die betroffenen Personen zusammenfassend auch als „Nutzer“).
        </p>

        <h2 className="font-semibold">6. Zweck der Verarbeitung</h2>
        <ul className="list-disc pl-8">
          <li>
            Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
            Inhalte
          </li>
          <li>
            Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern
          </li>
          <li>Sicherheitsmaßnahmen</li>
          <li>Reichweitenmessung/Marketing</li>
        </ul>

        <h2 className="font-semibold">7. Geschäftsbezogene Verarbeitung</h2>
        <p>Zusätzlich verarbeiten wir:</p>
        <ul className="list-disc pl-8">
          <li>
            Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie)
          </li>
          <li>Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)</li>
        </ul>
        <p>
          von unseren Kunden, Interessenten und Geschäftspartnern zwecks
          Erbringung vertraglicher Leistungen, Service und Kundenpflege,
          Marketing, Werbung und Marktforschung.
        </p>

        <h2 className="font-semibold">
          8. Erbringung vertraglicher Leistungen
        </h2>
        <p>
          Wir verarbeiten Bestandsdaten (z.B., Namen und Adressen sowie
          Kontaktdaten von betroffenen Personen), Vertragsdaten (z.B., in
          Anspruch genommene Leistungen, Namen von Kontaktpersonen,
          Zahlungsinformationen) zwecks Erfüllung unserer vertraglichen
          Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b. DSGVO.
          Die in Onlineformularen als verpflichtend gekennzeichneten Eingaben,
          sind für den Vertragsschluss erforderlich.
        </p>
        <p>
          Im Rahmen der Inanspruchnahme unserer Onlinedienste, speichern wir die
          IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
          Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als
          auch der betroffenen Person an Schutz vor Missbrauch und sonstiger
          unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
          grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche
          erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung
          gem. Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <p>
          Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres
          Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten
          (z.B., Eingaben im Kontaktformular oder Nutzerprofil) für Werbezwecke
          in einem Nutzerprofil, um der betroffenen Person z.B. Produkthinweise
          ausgehend von ihren bisher in Anspruch genommenen Leistungen
          einzublenden.
        </p>
        <p>
          Die Löschung der Daten erfolgt nach Ablauf gesetzlicher
          Gewährleistungs- und vergleichbarer Pflichten, die Erforderlichkeit
          der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der
          gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren
          Ablauf. Angaben im etwaigen Kundenkonto verbleiben bis zu dessen
          Löschung.
        </p>

        <h2 className="font-semibold">
          9. Zusammenarbeit mit Auftragsverarbeitern und Dritten
        </h2>
        <p>
          Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
          Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
          offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die
          Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
          Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an
          Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur
          Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine
          rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer
          berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
          Webhostern, etc.).
        </p>
        <p>
          Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines
          sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf
          Grundlage des Art. 28 DSGVO.
        </p>

        <h2 className="font-semibold">
          10. Berechtigte Interessen an der Verarbeitung, die von dem
          Verantwortlichen oder einem Dritten verfolgt werden
        </h2>
        <p>
          Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit.
          f DSGVO ist unser berechtigtes Interesse die Durchführung unserer
          Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter
          und unserer Anteilseigner.
        </p>

        <h2 className="font-semibold">
          11. Dauer, für die die personenbezogenen Daten gespeichert werden
        </h2>
        <p>
          Das Kriterium für die Dauer der Speicherung von personenbezogenen
          Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf
          der Frist werden die entsprechenden Daten routinemäßig gelöscht,
          sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung
          erforderlich sind.
        </p>

        <h2 className="font-semibold">
          12. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
          personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
          Verpflichtung der betroffenen Person, die personenbezogenen Daten
          bereitzustellen; mögliche Folgen der Nichtbereitstellung
        </h2>
        <p>
          Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener
          Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften)
          oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum
          Vertragspartner) ergeben kann. Mitunter kann es zu einem
          Vertragsschluss erforderlich sein, dass eine betroffene Person uns
          personenbezogene Daten zur Verfügung stellt, die in der Folge durch
          uns verarbeitet werden müssen. Die betroffene Person ist
          beispielsweise verpflichtet uns personenbezogene Daten
          bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag
          abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte
          zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen
          werden könnte. Vor einer Bereitstellung personenbezogener Daten durch
          den Betroffenen muss sich der Betroffene an unseren
          Datenschutzbeauftragten wenden. Unser Datenschutzbeauftragter klärt
          den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung
          der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben
          oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung
          besteht, die personenbezogenen Daten bereitzustellen, und welche
          Folgen die Nichtbereitstellung der personenbezogenen Daten hätte.
        </p>

        <h2 className="font-semibold">13. Cookies</h2>
        <p>
          Die Internetseiten der SEAS verwenden Cookies. Cookies sind
          Textdateien, welche über einen Internetbrowser auf einem
          Computersystem abgelegt und gespeichert werden.
        </p>
        <p>
          Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies
          enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
          eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge,
          durch welche Internetseiten und Server dem konkreten Internetbrowser
          zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
          ermöglicht es den besuchten Internetseiten und Servern, den
          individuellen Browser der betroffenen Person von anderen
          Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein
          bestimmter Internetbrowser kann über die eindeutige Cookie-ID
          wiedererkannt und identifiziert werden.
        </p>
        <p>
          Durch den Einsatz von Cookies kann die SEASden Nutzern dieser
          Internetseite nutzerfreundlichere Services bereitstellen, die ohne die
          Cookie-Setzung nicht möglich wären.
        </p>
        <p>
          Mittels eines Cookies können die Informationen und Angebote auf
          unserer Internetseite im Sinne des Benutzers optimiert werden. Cookies
          ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
          Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
          den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der
          Benutzer einer Internetseite, die Cookies verwendet, muss
          beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
          Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
          Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein
          weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
          Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen
          Warenkorb gelegt hat, über ein Cookie.
        </p>
        <p>
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite jederzeit mittels einer entsprechenden Einstellung des
          genutzten Internetbrowsers verhindern und damit der Setzung von
          Cookies dauerhaft widersprechen. Ferner können bereits gesetzte
          Cookies jederzeit über einen Internetbrowser oder andere
          Softwareprogramme gelöscht werden. Dies ist in allen gängigen
          Internetbrowsern möglich. Deaktiviert die betroffene Person die
          Setzung von Cookies in dem genutzten Internetbrowser, sind unter
          Umständen nicht alle Funktionen unserer Internetseite vollumfänglich
          nutzbar.
        </p>

        <h2 className="font-semibold">
          14. Erfassung von allgemeinen Daten und Informationen
        </h2>
        <p>
          Die Internetseite der SEAS erfasst mit jedem Aufruf der Internetseite
          durch eine betroffene Person oder ein automatisiertes System eine
          Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten
          und Informationen werden in den Logfiles des Servers gespeichert.
          Erfasst werden können die (1) verwendeten Browsertypen und Versionen,
          (2) das vom zugreifenden System verwendete Betriebssystem, (3) die
          Internetseite, von welcher ein zugreifendes System auf unsere
          Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten,
          welche über ein zugreifendes System auf unserer Internetseite
          angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf
          die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse),
          (7) der Internet-Service-Provider des zugreifenden Systems und (8)
          sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im
          Falle von Angriffen auf unsere informationstechnologischen Systeme
          dienen.
        </p>
        <p>
          Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die
          SEAS keine Rückschlüsse auf die betroffene Person. Diese Informationen
          werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite
          korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die
          Werbung für diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit
          unserer informationstechnologischen Systeme und der Technik unserer
          Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden
          im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
          Informationen bereitzustellen. Diese anonym erhobenen Daten und
          Informationen werden durch die SEASdaher einerseits statistisch und
          ferner mit dem Ziel ausgewertet, den Datenschutz und die
          Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein
          optimales Schutzniveau für die von uns verarbeiteten personenbezogenen
          Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden
          getrennt von allen durch eine betroffene Person angegebenen
          personenbezogenen Daten gespeichert.
        </p>

        <h2 className="font-semibold">15. Hosting</h2>
        <p>
          Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
          Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
          Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
          Datenbankdienste, Sicherheitsleistungen sowie technische
          Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
          Onlineangebotes einsetzen.
        </p>
        <p>
          Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
          Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
          Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
          Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
          effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
          gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
          Auftragsverarbeitungsvertrag).
        </p>

        <h2 className="font-semibold">
          16. Erhebung von Zugriffsdaten und Logfiles
        </h2>
        <p>
          Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
          berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
          über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
          (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
          abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
          Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
          Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
          besuchte Seite), IP-Adresse und der anfragende Provider.
        </p>
        <p>
          Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
          Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von
          maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere
          Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
          endgültigen Klärung des jeweiligen Vorfalls von der Löschung
          ausgenommen.
        </p>

        <h2 className="font-semibold">
          17. Registrierung auf unserer Internetseite
        </h2>
        <p>
          Die betroffene Person hat die Möglichkeit, sich auf der Internetseite
          des für die Verarbeitung Verantwortlichen unter Angabe von
          personenbezogenen Daten zu registrieren. Welche personenbezogenen
          Daten dabei an den für die Verarbeitung Verantwortlichen übermittelt
          werden, ergibt sich aus der jeweiligen Eingabemaske, die für die
          Registrierung verwendet wird. Die von der betroffenen Person
          eingegebenen personenbezogenen Daten werden ausschließlich für die
          interne Verwendung bei dem für die Verarbeitung Verantwortlichen und
          für eigene Zwecke erhoben und gespeichert. Der für die Verarbeitung
          Verantwortliche kann die Weitergabe an einen oder mehrere
          Auftragsverarbeiter, beispielsweise einen Paketdienstleister,
          veranlassen, der die personenbezogenen Daten ebenfalls ausschließlich
          für eine interne Verwendung, die dem für die Verarbeitung
          Verantwortlichen zuzurechnen ist, nutzt.
        </p>
        <p>
          Durch eine Registrierung auf der Internetseite des für die
          Verarbeitung Verantwortlichen wird ferner die vom
          Internet-Service-Provider (ISP) der betroffenen Person vergebene
          IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert.
          Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so
          der Missbrauch unserer Dienste verhindert werden kann, und diese Daten
          im Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern
          ist die Speicherung dieser Daten zur Absicherung des für die
          Verarbeitung Verantwortlichen erforderlich. Eine Weitergabe dieser
          Daten an Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche
          Pflicht zur Weitergabe besteht oder die Weitergabe der Strafverfolgung
          dient.
        </p>
        <p>
          Die Registrierung der betroffenen Person unter freiwilliger Angabe
          personenbezogener Daten dient dem für die Verarbeitung
          Verantwortlichen dazu, der betroffenen Person Inhalte oder Leistungen
          anzubieten, die aufgrund der Natur der Sache nur registrierten
          Benutzern angeboten werden können. Registrierten Personen steht die
          Möglichkeit frei, die bei der Registrierung angegebenen
          personenbezogenen Daten jederzeit abzuändern oder vollständig aus dem
          Datenbestand des für die Verarbeitung Verantwortlichen löschen zu
          lassen.
        </p>
        <p>
          Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen
          Person jederzeit auf Anfrage Auskunft darüber, welche
          personenbezogenen Daten über die betroffene Person gespeichert sind.
          Ferner berichtigt oder löscht der für die Verarbeitung Verantwortliche
          personenbezogene Daten auf Wunsch oder Hinweis der betroffenen Person,
          soweit dem keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
          Die Gesamtheit der Mitarbeiter des für die Verarbeitung
          Verantwortlichen stehen der betroffenen Person in diesem Zusammenhang
          als Ansprechpartner zur Verfügung.
        </p>

        <h2 className="font-semibold">18. Kontaktaufnahme</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
          Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
          Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1
          lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
          Customer-Relationship-Management System ("CRM System") oder
          vergleichbarer Anfragenorganisation gespeichert werden.
        </p>
        <p>
          Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind.
          Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
          gesetzlichen Archivierungspflichten.
        </p>

        <h2 className="font-semibold">
          19. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche verarbeitet und speichert
          personenbezogene Daten der betroffenen Person nur für den Zeitraum,
          der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern
          dies durch den Europäischen Richtlinien- und Verordnungsgeber oder
          einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der
          für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
        </p>
        <p>
          Entfällt der Speicherungszweck oder läuft eine vom Europäischen
          Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
          Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
          personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen
          Vorschriften gesperrt oder gelöscht.
        </p>

        <h2 className="font-semibold">20. Rechte der betroffenen Person</h2>
        <h2 className="font-semibold">a) Recht auf Bestätigung</h2>
        <p>
          Jede betroffene Person hat das vom Europäischen Richtlinien- und
          Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
          Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
          betreffende personenbezogene Daten verarbeitet werden. Möchte eine
          betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann
          sie sich hierzu jederzeit an einen Mitarbeiter des für die
          Verarbeitung Verantwortlichen wenden.
        </p>

        <h2 className="font-semibold">b) Recht auf Auskunft</h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, jederzeit von dem für die Verarbeitung Verantwortlichen
          unentgeltliche Auskunft über die zu seiner Person gespeicherten
          personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.
          Ferner hat der Europäische Richtlinien- und Verordnungsgeber der
          betroffenen Person Auskunft über folgende Informationen zugestanden:
        </p>
        <ul className="list-disc pl-8">
          <li>die Verarbeitungszwecke</li>
          <li>
            die Kategorien personenbezogener Daten, die verarbeitet werden
          </li>
          <li>
            die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen
          </li>
          <li>
            falls möglich die geplante Dauer, für die die personenbezogenen
            Daten gespeichert werden, oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung dieser Dauer
          </li>
          <li>
            das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
            betreffenden personenbezogenen Daten oder auf Einschränkung der
            Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung
          </li>
          <li>
            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde, wenn
            die personenbezogenen Daten nicht bei der betroffenen Person erhoben
            werden: Alle verfügbaren Informationen über die Herkunft der Daten
          </li>
          <li>
            das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und —
            zumindest in diesen Fällen — aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene Person
          </li>
        </ul>
        <p>
          Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
          personenbezogene Daten an ein Drittland oder an eine internationale
          Organisation übermittelt wurden. Sofern dies der Fall ist, so steht
          der betroffenen Person im Übrigen das Recht zu, Auskunft über die
          geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.
        </p>
        <p>
          Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch
          nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
          die Verarbeitung Verantwortlichen wenden.
        </p>

        <h2 className="font-semibold">c) Recht auf Berichtigung</h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, die unverzügliche Berichtigung sie betreffender unrichtiger
          personenbezogener Daten zu verlangen. Ferner steht der betroffenen
          Person das Recht zu, unter Berücksichtigung der Zwecke der
          Verarbeitung, die Vervollständigung unvollständiger personenbezogener
          Daten — auch mittels einer ergänzenden Erklärung — zu verlangen.
        </p>
        <p>
          Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch
          nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
          die Verarbeitung Verantwortlichen wenden.
        </p>

        <h2 className="font-semibold">
          d) Recht auf Löschung (Recht auf Vergessen werden)
        </h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, von dem Verantwortlichen zu verlangen, dass die sie
          betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
          sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung
          nicht erforderlich ist:
        </p>
        <ul className="list-disc pl-8">
          <li>
            Die personenbezogenen Daten wurden für solche Zwecke erhoben oder
            auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig
            sind.
          </li>
          <li>
            Die betroffene Person widerruft ihre Einwilligung, auf die sich die
            Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs.
            2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen
            Rechtsgrundlage für die Verarbeitung.
          </li>
          <li>
            Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch
            gegen die Verarbeitung ein, und es liegen keine vorrangigen
            berechtigten Gründe für die Verarbeitung vor, oder die betroffene
            Person legt gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die
            Verarbeitung ein.
          </li>
          <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
          <li>
            Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
          </li>
          <li>
            Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
            der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
          </li>
        </ul>
        <p>
          Sofern einer der oben genannten Gründe zutrifft und eine betroffene
          Person die Löschung von personenbezogenen Daten, die bei der SEAS
          gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit
          an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
          Ein Mitarbeiter der SEAS wird veranlassen, dass dem Löschverlangen
          unverzüglich nachgekommen wird.
        </p>
        <p>
          Wurden die personenbezogenen Daten von der SEAS öffentlich gemacht und
          ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO
          zur Löschung der personenbezogenen Daten verpflichtet, so trifft die
          SEAS unter Berücksichtigung der verfügbaren Technologie und der
          Implementierungskosten angemessene Maßnahmen, auch technischer Art, um
          andere für die Datenverarbeitung Verantwortliche, welche die
          veröffentlichten personenbezogenen Daten verarbeiten, darüber in
          Kenntnis zu setzen, dass die betroffene Person von diesen anderen für
          die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links
          zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
          dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung
          nicht erforderlich ist. Ein Mitarbeiter der SEAS wird im Einzelfall
          das Notwendige veranlassen.
        </p>

        <h2 className="font-semibold">
          e) Recht auf Einschränkung der Verarbeitung
        </h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu
          verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
        </p>
        <ul className="list-disc pl-8">
          <li>
            Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
            Person bestritten, und zwar für eine Dauer, die es dem
            Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
            Daten zu überprüfen.
          </li>
          <li>
            Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die
            Löschung der personenbezogenen Daten ab und verlangt stattdessen die
            Einschränkung der Nutzung der personenbezogenen Daten.
          </li>
          <li>
            Der Verantwortliche benötigt die personenbezogenen Daten für die
            Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt
            sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
          </li>
          <li>
            Die betroffene Person hat Widerspruch gegen die Verarbeitung gem.
            Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob die
            berechtigten Gründe des Verantwortlichen gegenüber denen der
            betroffenen Person überwiegen.
          </li>
        </ul>
        <p>
          Sofern eine der oben genannten Voraussetzungen gegeben ist und eine
          betroffene Person die Einschränkung von personenbezogenen Daten, die
          bei der SEASgespeichert sind, verlangen möchte, kann sie sich hierzu
          jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden. Ein Mitarbeiter der SEAS wird die
          Einschränkung der Verarbeitung veranlassen.
        </p>

        <h2 className="font-semibold">f) Recht auf Datenübertragbarkeit</h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, die sie betreffenden personenbezogenen Daten, welche durch die
          betroffene Person einem Verantwortlichen bereitgestellt wurden, in
          einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten. Sie hat außerdem das Recht, diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a
          DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag
          gemäß Art. 6 Abs. 1 Buchstabe b DSGVO beruht und die Verarbeitung
          mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung
          nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im
          öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
          erfolgt, welche dem Verantwortlichen übertragen wurde.
        </p>
        <p>
          Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
          Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO das Recht, zu
          erwirken, dass die personenbezogenen Daten direkt von einem
          Verantwortlichen an einen anderen Verantwortlichen übermittelt werden,
          soweit dies technisch machbar ist und sofern hiervon nicht die Rechte
          und Freiheiten anderer Personen beeinträchtigt werden.
        </p>
        <p>
          Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
          betroffene Person jederzeit an einen Mitarbeiter der SEAS wenden.
        </p>

        <h2 className="font-semibold">g) Recht auf Widerspruch</h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung sie betreffender personenbezogener
          Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DSGVO
          erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese
          Bestimmungen gestütztes Profiling.
        </p>
        <p>
          Die SEAS verarbeitet die personenbezogenen Daten im Falle des
          Widerspruchs nicht mehr, es sei denn, wir können zwingende
          schutzwürdige Gründe für die Verarbeitung nachweisen, die den
          Interessen, Rechten und Freiheiten der betroffenen Person überwiegen,
          oder die Verarbeitung dient der Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          Verarbeitet die SEAS personenbezogene Daten, um Direktwerbung zu
          betreiben, so hat die betroffene Person das Recht, jederzeit
          Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum
          Zwecke derartiger Werbung einzulegen. Dies gilt auch für das
          Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
          Widerspricht die betroffene Person gegenüber der SEAS der Verarbeitung
          für Zwecke der Direktwerbung, so wird die SEAS die personenbezogenen
          Daten nicht mehr für diese Zwecke verarbeiten.
        </p>
        <p>
          Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus
          ihrer besonderen Situation ergeben, gegen die sie betreffende
          Verarbeitung personenbezogener Daten, die bei der SEAS zu
          wissenschaftlichen oder historischen Forschungszwecken oder zu
          statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgen, Widerspruch
          einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung
          einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
        </p>
        <p>
          Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene
          Person direkt an einen Mitarbeiter der SEAS wenden. Der betroffenen
          Person steht es ferner frei, im Zusammenhang mit der Nutzung von
          Diensten der Informationsgesellschaft, ungeachtet der Richtlinie
          2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren
          auszuüben, bei denen technische Spezifikationen verwendet werden.
        </p>

        <h2 className="font-semibold">
          h) Automatisierte Entscheidungen im Einzelfall einschließlich
          Profiling
        </h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
          unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet
          oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die
          Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines
          Vertrags zwischen der betroffenen Person und dem Verantwortlichen
          erforderlich ist, oder (2) aufgrund von Rechtsvorschriften der Union
          oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
          zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur
          Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen
          der betroffenen Person enthalten oder (3) mit ausdrücklicher
          Einwilligung der betroffenen Person erfolgt.
        </p>
        <p>
          Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines
          Vertrags zwischen der betroffenen Person und dem Verantwortlichen
          erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung der
          betroffenen Person, trifft die SEAS angemessene Maßnahmen, um die
          Rechte und Freiheiten sowie die berechtigten Interessen der
          betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung
          des Eingreifens einer Person seitens des Verantwortlichen, auf
          Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
          gehört.
        </p>
        <p>
          Möchte die betroffene Person Rechte mit Bezug auf automatisierte
          Entscheidungen geltend machen, kann sie sich hierzu jederzeit an einen
          Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
        </p>

        <h2 className="font-semibold">
          i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
        </h2>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten
          jederzeit zu widerrufen.
        </p>
        <p>
          Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung
          geltend machen, kann sie sich hierzu jederzeit an einen Mitarbeiter
          des für die Verarbeitung Verantwortlichen wenden.
        </p>

        <h2 className="font-semibold">
          21. Onlinepräsenzen in sozialen Medien
        </h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
          kommunizieren und sie dort über unsere Leistungen informieren zu
          können. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten
          die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren
          jeweiligen Betreiber.
        </p>
        <p>
          Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben,
          verarbeiten wir die Daten der Nutzer sofern diese mit uns innerhalb
          der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge
          auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.
        </p>

        <h2 className="font-semibold">
          22. Einbindung von Diensten und Inhalten Dritter
        </h2>
        <p>
          Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
          berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
          und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art.
          6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
          Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
          oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als
          “Inhalte”).
        </p>
        <p>
          Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
          IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die
          Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
          damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns
          nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
          IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
          Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare
          Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
          Marketingzwecke verwenden. Durch die "Pixel-Tags" können
          Informationen, wie der Besucherverkehr auf den Seiten dieser Website
          ausgewertet werden. Die pseudonymen Informationen können ferner in
          Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem
          technische Informationen zum Browser und Betriebssystem, verweisende
          Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres
          Onlineangebotes enthalten, als auch mit solchen Informationen aus
          anderen Quellen verbunden werden.
        </p>

        <h2 className="font-semibold">
          23. Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          Komponenten des Unternehmens Facebook integriert. Facebook ist ein
          soziales Netzwerk.
        </p>
        <p>
          Ein soziales Netzwerk ist ein im Internet betriebener sozialer
          Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der Regel
          ermöglicht, untereinander zu kommunizieren und im virtuellen Raum zu
          interagieren. Ein soziales Netzwerk kann als Plattform zum Austausch
          von Meinungen und Erfahrungen dienen oder ermöglicht es der
          Internetgemeinschaft, persönliche oder unternehmensbezogene
          Informationen bereitzustellen. Facebook ermöglicht den Nutzern des
          sozialen Netzwerkes unter anderem die Erstellung von privaten
          Profilen, den Upload von Fotos und eine Vernetzung über
          Freundschaftsanfragen.
        </p>
        <p>
          Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker
          Way, Menlo Park, CA 94025, USA. Für die Verarbeitung personenbezogener
          Daten Verantwortlicher ist, wenn eine betroffene Person außerhalb der
          USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Ireland.
        </p>
        <p>
          Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die
          durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
          welcher eine Facebook-Komponente (Facebook-Plug-In) integriert wurde,
          wird der Internetbrowser auf dem informationstechnologischen System
          der betroffenen Person automatisch durch die jeweilige
          Facebook-Komponente veranlasst, eine Darstellung der entsprechenden
          Facebook-Komponente von Facebook herunterzuladen. Eine Gesamtübersicht
          über alle Facebook-Plug-Ins kann unter
          https://developers.facebook.com/docs/plugins/?locale=de_DE abgerufen
          werden. Im Rahmen dieses technischen Verfahrens erhält Facebook
          Kenntnis darüber, welche konkrete Unterseite unserer Internetseite
          durch die betroffene Person besucht wird.
        </p>
        <p>
          Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist,
          erkennt Facebook mit jedem Aufruf unserer Internetseite durch die
          betroffene Person und während der gesamten Dauer des jeweiligen
          Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
          unserer Internetseite die betroffene Person besucht. Diese
          Informationen werden durch die Facebook-Komponente gesammelt und durch
          Facebook dem jeweiligen Facebook-Account der betroffenen Person
          zugeordnet. Betätigt die betroffene Person einen der auf unserer
          Internetseite integrierten Facebook-Buttons, beispielsweise den
          „Gefällt mir“-Button, oder gibt die betroffene Person einen Kommentar
          ab, ordnet Facebook diese Information dem persönlichen
          Facebook-Benutzerkonto der betroffenen Person zu und speichert diese
          personenbezogenen Daten.
        </p>
        <p>
          Facebook erhält über die Facebook-Komponente immer dann eine
          Information darüber, dass die betroffene Person unsere Internetseite
          besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs
          unserer Internetseite gleichzeitig bei Facebook eingeloggt ist; dies
          findet unabhängig davon statt, ob die betroffene Person die
          Facebook-Komponente anklickt oder nicht. Ist eine derartige
          Übermittlung dieser Informationen an Facebook von der betroffenen
          Person nicht gewollt, kann diese die Übermittlung dadurch verhindern,
          dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
          Facebook-Account ausloggt.
        </p>
        <p>
          Die von Facebook veröffentlichte Datenrichtlinie, die unter
          https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt
          Aufschluss über die Erhebung, Verarbeitung und Nutzung
          personenbezogener Daten durch Facebook. Ferner wird dort erläutert,
          welche Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre
          der betroffenen Person bietet. Zudem sind unterschiedliche
          Applikationen erhältlich, die es ermöglichen, eine Datenübermittlung
          an Facebook zu unterdrücken. Solche Applikationen können durch die
          betroffene Person genutzt werden, um eine Datenübermittlung an
          Facebook zu unterdrücken.
        </p>

        <h2 className="font-semibold">
          24. Verwendung von Facebook Social Plugins
        </h2>
        <p>
          Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h.
          Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
          unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
          Social Plugins ("Plugins") des sozialen Netzwerkes facebook.com,
          welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand
          Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"). Die
          Plugins können Interaktionselemente oder Inhalte (z.B. Videos,
          Grafiken oder Textbeiträge) darstellen und sind an einem der Facebook
          Logos erkennbar (weißes „f“ auf blauer Kachel, den Begriffen "Like",
          "Gefällt mir" oder einem „Daumen hoch“-Zeichen) oder sind mit dem
          Zusatz "Facebook Social Plugin" gekennzeichnet. Die Liste und das
          Aussehen der Facebook Social Plugins kann hier eingesehen werden:
          https://developers.facebook.com/docs/plugins/.
        </p>
        <p>
          Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
          hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active).
        </p>
        <p>
          Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein
          solches Plugin enthält, baut sein Gerät eine direkte Verbindung mit
          den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook
          direkt an das Gerät des Nutzers übermittelt und von diesem in das
          Onlineangebot eingebunden. Dabei können aus den verarbeiteten Daten
          Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen
          Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses
          Plugins erhebt und informieren die Nutzer hiermit entsprechend unserem
          Kenntnisstand.
        </p>
        <p>
          Durch die Einbindung der Plugins erhält Facebook die Information, dass
          ein Nutzer die entsprechende Seite des Onlineangebotes aufgerufen hat.
          Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch
          seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins
          interagieren, zum Beispiel den Like Button betätigen oder einen
          Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät
          direkt an Facebook übermittelt und dort gespeichert. Falls ein Nutzer
          kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, dass
          Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut
          Facebook wird in Deutschland nur eine anonymisierte IP-Adresse
          gespeichert.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
          Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und
          Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer,
          können diese den Datenschutzhinweisen von Facebook entnehmen:
          https://www.facebook.com/about/privacy/.
        </p>
        <p>
          Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook
          über dieses Onlineangebot Daten über ihn sammelt und mit seinen bei
          Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der
          Nutzung unseres Onlineangebotes bei Facebook ausloggen und seine
          Cookies löschen. Weitere Einstellungen und Widersprüche zur Nutzung
          von Daten für Werbezwecke, sind innerhalb der
          Facebook-Profileinstellungen möglich:
          https://www.facebook.com/settings?tab=ads oder über die
          US-amerikanische Seite http://www.aboutads.info/choices/ oder die
          EU-Seite http://www.youronlinechoices.com/. Die Einstellungen erfolgen
          plattformunabhängig, d.h. sie werden für alle Geräte, wie
          Desktopcomputer oder mobile Geräte übernommen.
        </p>
        <p>
          Internetnutzern Werbung einblenden zu lassen, die sich zuvor auf der
          Internetseite des Unternehmens aufgehalten haben. Die Integration von
          AdRoll gestattet es einem Unternehmen demnach, nutzerbezogene Werbung
          zu erstellen und dem Internetnutzer folglich interessenrelevante
          Werbeanzeigen anzeigen zu lassen.
        </p>

        <h2 className="font-semibold">
          25. Datenschutzbestimmungen zu Einsatz und Verwendung von Google
          AdSense
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          Google AdSense integriert. Google AdSense ist ein Online-Dienst, über
          welchen eine Vermittlung von Werbung auf Drittseiten ermöglicht wird.
          Google AdSense beruht auf einem Algorithmus, welcher die auf
          Drittseiten angezeigten Werbeanzeigen passend zu den Inhalten der
          jeweiligen Drittseite auswählt. Google AdSense gestattet ein
          interessenbezogenes Targeting des Internetnutzers, welches mittels
          Generierung von individuellen Benutzerprofilen umgesetzt wird.
        </p>
        <p>
          Betreibergesellschaft der Google-AdSense-Komponente ist die Alphabet
          Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
        </p>
        <p>
          Der Zweck der Google-AdSense-Komponente ist die Einbindung von
          Werbeanzeigen auf unserer Internetseite. Google-AdSense setzt ein
          Cookie auf dem informationstechnologischen System der betroffenen
          Person. Was Cookies sind, wurde oben bereits erläutert. Mit der
          Setzung des Cookies wird der Alphabet Inc. eine Analyse der Benutzung
          unserer Internetseite ermöglicht. Durch jeden Aufruf einer der
          Einzelseiten dieser Internetseite, die durch den für die Verarbeitung
          Verantwortlichen betrieben wird und auf welcher eine
          Google-AdSense-Komponente integriert wurde, wird der Internetbrowser
          auf dem informationstechnologischen System der betroffenen Person
          automatisch durch die jeweilige Google-AdSense-Komponente veranlasst,
          Daten zum Zwecke der Online-Werbung und der Abrechnung von Provisionen
          an die Alphabet Inc. zu übermitteln. Im Rahmen dieses technischen
          Verfahrens erhält die Alphabet Inc. Kenntnis über personenbezogene
          Daten, wie der IP-Adresse der betroffenen Person, die der Alphabet
          Inc. unter anderem dazu dienen, die Herkunft der Besucher und Klicks
          nachzuvollziehen und in der Folge Provisionsabrechnungen zu
          ermöglichen.
        </p>
        <p>
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite, wie oben bereits dargestellt, jederzeit mittels einer
          entsprechenden Einstellung des genutzten Internetbrowsers verhindern
          und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
          Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
          die Alphabet Inc. ein Cookie auf dem informationstechnologischen
          System der betroffenen Person setzt. Zudem kann ein von der Alphabet
          Inc. bereits gesetzter Cookie jederzeit über den Internetbrowser oder
          andere Softwareprogramme gelöscht werden.
        </p>
        <p>
          Google AdSense verwendet zudem sogenannte Zählpixel. Ein Zählpixel ist
          eine Miniaturgrafik, die in Internetseiten eingebettet wird, um eine
          Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen,
          wodurch eine statistische Auswertung durchgeführt werden kann. Anhand
          des eingebetteten Zählpixels kann die Alphabet Inc. erkennen, ob und
          wann eine Internetseite von einer betroffenen Person geöffnet wurde
          und welche Links von der betroffenen Person angeklickt wurden.
          Zählpixel dienen unter anderem dazu, den Besucherfluss einer
          Internetseite auszuwerten.
        </p>
        <p>
          Über Google AdSense werden personenbezogene Daten und Informationen,
          was auch die IP-Adresse umfasst und zur Erfassung und Abrechnung der
          angezeigten Werbeanzeigen notwendig ist, an die Alphabet Inc. in die
          Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen
          Daten werden in den Vereinigten Staaten von Amerika gespeichert und
          verarbeitet. Die Alphabet Inc. gibt diese über das technische
          Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte
          weiter.
        </p>
        <p>
          Google-AdSense wird unter diesem Link
          https://www.google.de/intl/de/adsense/start/ genauer erläutert.
        </p>

        <h2 className="font-semibold">
          26. Datenschutzbestimmungen zu Einsatz und Verwendung von Google
          Analytics (mit Anonymisierungsfunktion)
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          die Komponente Google Analytics (mit Anonymisierungsfunktion)
          integriert. Google Analytics ist ein Web-Analyse-Dienst. Web-Analyse
          ist die Erhebung, Sammlung und Auswertung von Daten über das Verhalten
          von Besuchern von Internetseiten. Ein Web-Analyse-Dienst erfasst unter
          anderem Daten darüber, von welcher Internetseite eine betroffene
          Person auf eine Internetseite gekommen ist (sogenannte Referrer), auf
          welche Unterseiten der Internetseite zugegriffen oder wie oft und für
          welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse
          wird überwiegend zur Optimierung einer Internetseite und zur
          Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
        </p>
        <p>
          Betreibergesellschaft der Google-Analytics-Komponente ist die Google
          Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
        </p>
        <p>
          Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse
          über Google Analytics den Zusatz "_gat._anonymizeIp". Mittels dieses
          Zusatzes wird die IP-Adresse des Internetanschlusses der betroffenen
          Person von Google gekürzt und anonymisiert, wenn der Zugriff auf
          unsere Internetseiten aus einem Mitgliedstaat der Europäischen Union
          oder aus einem anderen Vertragsstaat des Abkommens über den
          Europäischen Wirtschaftsraum erfolgt.
        </p>
        <p>
          Der Zweck der Google-Analytics-Komponente ist die Analyse der
          Besucherströme auf unserer Internetseite. Google nutzt die gewonnenen
          Daten und Informationen unter anderem dazu, die Nutzung unserer
          Internetseite auszuwerten, um für uns Online-Reports, welche die
          Aktivitäten auf unseren Internetseiten aufzeigen, zusammenzustellen,
          und um weitere mit der Nutzung unserer Internetseite in Verbindung
          stehende Dienstleistungen zu erbringen.
        </p>
        <p>
          Google Analytics setzt ein Cookie auf dem informationstechnologischen
          System der betroffenen Person. Was Cookies sind, wurde oben bereits
          erläutert. Mit Setzung des Cookies wird Google eine Analyse der
          Benutzung unserer Internetseite ermöglicht. Durch jeden Aufruf einer
          der Einzelseiten dieser Internetseite, die durch den für die
          Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
          Google-Analytics-Komponente integriert wurde, wird der Internetbrowser
          auf dem informationstechnologischen System der betroffenen Person
          automatisch durch die jeweilige Google-Analytics-Komponente
          veranlasst, Daten zum Zwecke der Online-Analyse an Google zu
          übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google
          Kenntnis über personenbezogene Daten, wie der IP-Adresse der
          betroffenen Person, die Google unter anderem dazu dienen, die Herkunft
          der Besucher und Klicks nachzuvollziehen und in der Folge
          Provisionsabrechnungen zu ermöglichen.
        </p>
        <p>
          Mittels des Cookies werden personenbezogene Informationen,
          beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff
          ausging und die Häufigkeit der Besuche unserer Internetseite durch die
          betroffene Person, gespeichert. Bei jedem Besuch unserer
          Internetseiten werden diese personenbezogenen Daten, einschließlich
          der IP-Adresse des von der betroffenen Person genutzten
          Internetanschlusses, an Google in den Vereinigten Staaten von Amerika
          übertragen. Diese personenbezogenen Daten werden durch Google in den
          Vereinigten Staaten von Amerika gespeichert. Google gibt diese über
          das technische Verfahren erhobenen personenbezogenen Daten unter
          Umständen an Dritte weiter.
        </p>
        <p>
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite, wie oben bereits dargestellt, jederzeit mittels einer
          entsprechenden Einstellung des genutzten Internetbrowsers verhindern
          und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
          Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
          Google ein Cookie auf dem informationstechnologischen System der
          betroffenen Person setzt. Zudem kann ein von Google Analytics bereits
          gesetzter Cookie jederzeit über den Internetbrowser oder andere
          Softwareprogramme gelöscht werden.
        </p>
        <p>
          Ferner besteht für die betroffene Person die Möglichkeit, einer
          Erfassung der durch Google Analytics erzeugten, auf eine Nutzung
          dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser
          Daten durch Google zu widersprechen und eine solche zu verhindern.
          Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link
          https://tools.google.com/dlpage/gaoptout herunterladen und
          installieren. Dieses Browser-Add-On teilt Google Analytics über
          JavaScript mit, dass keine Daten und Informationen zu den Besuchen von
          Internetseiten an Google Analytics übermittelt werden dürfen. Die
          Installation des Browser-Add-Ons wird von Google als Widerspruch
          gewertet. Wird das informationstechnologische System der betroffenen
          Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
          installiert, muss durch die betroffene Person eine erneute
          Installation des Browser-Add-Ons erfolgen, um Google Analytics zu
          deaktivieren. Sofern das Browser-Add-On durch die betroffene Person
          oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist,
          deinstalliert oder deaktiviert wird, besteht die Möglichkeit der
          Neuinstallation oder der erneuten Aktivierung des Browser-Add-Ons.
        </p>
        <p>
          Weitere Informationen und die geltenden Datenschutzbestimmungen von
          Google können unter https://www.google.de/intl/de/policies/privacy/
          und unter http://www.google.com/analytics/terms/de.html abgerufen
          werden. Google Analytics wird unter diesem Link
          https://www.google.com/intl/de_de/analytics/ genauer erläutert.
        </p>

        <h2 className="font-semibold">
          27. Datenschutzbestimmungen zu Einsatz und Verwendung von
          Google-AdWords
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          Google AdWords integriert. Google AdWords ist ein Dienst zur
          Internetwerbung, der es Werbetreibenden gestattet, sowohl Anzeigen in
          den Suchmaschinenergebnissen von Google als auch im
          Google-Werbenetzwerk zu schalten. Google AdWords ermöglicht es einem
          Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen, mittels
          derer eine Anzeige in den Suchmaschinenergebnissen von Google
          ausschließlich dann angezeigt wird, wenn der Nutzer mit der
          Suchmaschine ein schlüsselwortrelevantes Suchergebnis abruft. Im
          Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen
          Algorithmus und unter Beachtung der zuvor festgelegten Schlüsselwörter
          auf themenrelevanten Internetseiten verteilt.
        </p>
        <p>
          Betreibergesellschaft der Dienste von Google AdWords ist die Google
          Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
        </p>
        <p>
          Der Zweck von Google AdWords ist die Bewerbung unserer Internetseite
          durch die Einblendung von interessenrelevanter Werbung auf den
          Internetseiten von Drittunternehmen und in den
          Suchmaschinenergebnissen der Suchmaschine Google und eine Einblendung
          von Fremdwerbung auf unserer Internetseite.
        </p>
        <p>
          Gelangt eine betroffene Person über eine Google-Anzeige auf unsere
          Internetseite, wird auf dem informationstechnologischen System der
          betroffenen Person durch Google ein sogenannter Conversion-Cookie
          abgelegt. Was Cookies sind, wurde oben bereits erläutert. Ein
          Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und
          dient nicht zur Identifikation der betroffenen Person. Über den
          Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen ist,
          nachvollzogen, ob bestimmte Unterseiten, beispielsweise der Warenkorb
          von einem Online-Shop-System, auf unserer Internetseite aufgerufen
          wurden. Durch den Conversion-Cookie können sowohl wir als auch Google
          nachvollziehen, ob eine betroffene Person, die über eine
          AdWords-Anzeige auf unsere Internetseite gelangt ist, einen Umsatz
          generierte, also einen Warenkauf vollzogen oder abgebrochen hat.
        </p>
        <p>
          Die durch die Nutzung des Conversion-Cookies erhobenen Daten und
          Informationen werden von Google verwendet, um Besuchsstatistiken für
          unsere Internetseite zu erstellen. Diese Besuchsstatistiken werden
          durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer zu
          ermitteln, welche über AdWords-Anzeigen an uns vermittelt wurden, also
          um den Erfolg oder Misserfolg der jeweiligen AdWords-Anzeige zu
          ermitteln und um unsere AdWords-Anzeigen für die Zukunft zu
          optimieren. Weder unser Unternehmen noch andere Werbekunden von
          Google-AdWords erhalten Informationen von Google, mittels derer die
          betroffene Person identifiziert werden könnte.
        </p>
        <p>
          Mittels des Conversion-Cookies werden personenbezogene Informationen,
          beispielsweise die durch die betroffene Person besuchten
          Internetseiten, gespeichert. Bei jedem Besuch unserer Internetseiten
          werden demnach personenbezogene Daten, einschließlich der IP-Adresse
          des von der betroffenen Person genutzten Internetanschlusses, an
          Google in den Vereinigten Staaten von Amerika übertragen. Diese
          personenbezogenen Daten werden durch Google in den Vereinigten Staaten
          von Amerika gespeichert. Google gibt diese über das technische
          Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte
          weiter.
        </p>
        <p>
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite, wie oben bereits dargestellt, jederzeit mittels einer
          entsprechenden Einstellung des genutzten Internetbrowsers verhindern
          und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
          Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
          Google einen Conversion-Cookie auf dem informationstechnologischen
          System der betroffenen Person setzt. Zudem kann ein von Google AdWords
          bereits gesetzter Cookie jederzeit über den Internetbrowser oder
          andere Softwareprogramme gelöscht werden.
        </p>
        <p>
          Ferner besteht für die betroffene Person die Möglichkeit, der
          interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss
          die betroffene Person von jedem der von ihr genutzten Internetbrowser
          aus den Link www.google.de/settings/ads aufrufen und dort die
          gewünschten Einstellungen vornehmen.
        </p>
        <p>
          Weitere Informationen und die geltenden Datenschutzbestimmungen von
          Google können unter https://www.google.de/intl/de/policies/privacy/
          abgerufen werden.
        </p>

        <h2 className="font-semibold">28. Google Maps</h2>
        <p>
          Wir können die Landkarten des Dienstes “Google Maps” des Anbieters
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
          einbinden. Datenschutzerklärung:
          https://www.google.com/policies/privacy/, Opt-Out:
          https://adssettings.google.com/authenticated.
        </p>

        <h2 className="font-semibold">
          29. Datenschutzbestimmungen zu Einsatz und Verwendung von Instagram
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          Komponenten des Dienstes Instagram integriert. Instagram ist ein
          Dienst, der als audiovisuelle Plattform zu qualifizieren ist und den
          Nutzern das Teilen von Fotos und Videos und zudem eine
          Weiterverbreitung solcher Daten in anderen sozialen Netzwerken
          ermöglicht.
        </p>
        <p>
          Betreibergesellschaft der Dienste von Instagram ist die Instagram LLC,
          1 Hacker Way, Building 14 First Floor, Menlo Park, CA, USA.
        </p>
        <p>
          Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die
          durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
          welcher eine Instagram-Komponente (Insta-Button) integriert wurde,
          wird der Internetbrowser auf dem informationstechnologischen System
          der betroffenen Person automatisch durch die jeweilige
          Instagram-Komponente veranlasst, eine Darstellung der entsprechenden
          Komponente von Instagram herunterzuladen. Im Rahmen dieses technischen
          Verfahrens erhält Instagram Kenntnis darüber, welche konkrete
          Unterseite unserer Internetseite durch die betroffene Person besucht
          wird.
        </p>
        <p>
          Sofern die betroffene Person gleichzeitig bei Instagram eingeloggt
          ist, erkennt Instagram mit jedem Aufruf unserer Internetseite durch
          die betroffene Person und während der gesamten Dauer des jeweiligen
          Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite die
          betroffene Person besucht. Diese Informationen werden durch die
          Instagram-Komponente gesammelt und durch Instagram dem jeweiligen
          Instagram-Account der betroffenen Person zugeordnet. Betätigt die
          betroffene Person einen der auf unserer Internetseite integrierten
          Instagram-Buttons, werden die damit übertragenen Daten und
          Informationen dem persönlichen Instagram-Benutzerkonto der betroffenen
          Person zugeordnet und von Instagram gespeichert und verarbeitet.
        </p>
        <p>
          Instagram erhält über die Instagram-Komponente immer dann eine
          Information darüber, dass die betroffene Person unsere Internetseite
          besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs
          unserer Internetseite gleichzeitig bei Instagram eingeloggt ist; dies
          findet unabhängig davon statt, ob die betroffene Person die
          Instagram-Komponente anklickt oder nicht. Ist eine derartige
          Übermittlung dieser Informationen an Instagram von der betroffenen
          Person nicht gewollt, kann diese die Übermittlung dadurch verhindern,
          dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
          Instagram-Account ausloggt.
        </p>
        <p>
          Weitere Informationen und die geltenden Datenschutzbestimmungen von
          Instagram können unter https://help.instagram.com/155833707900388 und
          https://www.instagram.com/about/legal/privacy/ abgerufen werden.
        </p>

        <h2 className="font-semibold">
          30. Datenschutzbestimmungen zu Einsatz und Verwendung von LinkedIn
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          Komponenten der LinkedIn Corporation integriert. LinkedIn ist ein
          Internetbasiertes soziales Netzwerk, das eine Konnektierung der Nutzer
          mit bestehenden Geschäftskontakten sowie das Knüpfen von neuen
          Businesskontakten ermöglicht. Über 400 Millionen registrierte Personen
          nutzen LinkedIn in mehr als 200 Ländern. Damit ist LinkedIn derzeit
          die größte Plattform für Businesskontakte und eine der meistbesuchten
          Internetseiten der Welt.
        </p>
        <p>
          Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation, 2029
          Stierlin Court Mountain View, CA 94043, USA. Für
          Datenschutzangelegenheiten außerhalb der USA ist LinkedIn Ireland,
          Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2, Ireland,
          zuständig.
        </p>
        <p>
          Bei jedem einzelnen Abruf unserer Internetseite, die mit einer
          LinkedIn-Komponente (LinkedIn-Plug-In) ausgestattet ist, veranlasst
          diese Komponente, dass der von der betroffenen Person verwendete
          Browser eine entsprechende Darstellung der Komponente von LinkedIn
          herunterlädt. Weitere Informationen zu den LinkedIn-Plug-Ins können
          unter https://developer.linkedin.com/plugins abgerufen werden. Im
          Rahmen dieses technischen Verfahrens erhält LinkedIn Kenntnis darüber,
          welche konkrete Unterseite unserer Internetseite durch die betroffene
          Person besucht wird.
        </p>
        <p>
          Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist,
          erkennt LinkedIn mit jedem Aufruf unserer Internetseite durch die
          betroffene Person und während der gesamten Dauer des jeweiligen
          Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
          unserer Internetseite die betroffene Person besucht. Diese
          Informationen werden durch die LinkedIn-Komponente gesammelt und durch
          LinkedIn dem jeweiligen LinkedIn-Account der betroffenen Person
          zugeordnet. Betätigt die betroffene Person einen auf unserer
          Internetseite integrierten LinkedIn-Button, ordnet LinkedIn diese
          Information dem persönlichen LinkedIn-Benutzerkonto der betroffenen
          Person zu und speichert diese personenbezogenen Daten.
        </p>
        <p>
          LinkedIn erhält über die LinkedIn-Komponente immer dann eine
          Information darüber, dass die betroffene Person unsere Internetseite
          besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufes
          unserer Internetseite gleichzeitig bei LinkedIn eingeloggt ist; dies
          findet unabhängig davon statt, ob die betroffene Person die
          LinkedIn-Komponente anklickt oder nicht. Ist eine derartige
          Übermittlung dieser Informationen an LinkedIn von der betroffenen
          Person nicht gewollt, kann diese die Übermittlung dadurch verhindern,
          dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
          LinkedIn-Account ausloggt.
        </p>
        <p>
          LinkedIn bietet unter
          https://www.linkedin.com/psettings/guest-controls die Möglichkeit,
          E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete Anzeigen
          abzubestellen sowie Anzeigen-Einstellungen zu verwalten. LinkedIn
          nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai,
          DoubleClick, Nielsen, Comscore, Eloqua und Lotame, die Cookies setzen
          können. Solche Cookies können unter
          https://www.linkedin.com/legal/cookie-policy abgelehnt werden. Die
          geltenden Datenschutzbestimmungen von LinkedIn sind unter
          https://www.linkedin.com/legal/privacy-policy abrufbar. Die
          Cookie-Richtlinie von LinkedIn.
        </p>

        <h2 className="font-semibold">
          31. Datenschutzbestimmungen zu Einsatz und Verwendung von Twitter
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche kann auf dieser Internetseite
          Komponenten von Twitter integrieren. Twitter ist ein multilingualer
          öffentlich zugänglicher Mikroblogging-Dienst, auf welchem die Nutzer
          sogenannte Tweets, also Kurznachrichten, die auf 140 Zeichen begrenzt
          sind, veröffentlichen und verbreiten können. Diese Kurznachrichten
          sind für jedermann, also auch für nicht bei Twitter angemeldete
          Personen abrufbar. Die Tweets werden aber auch den sogenannten
          Followern des jeweiligen Nutzers angezeigt. Follower sind andere
          Twitter-Nutzer, die den Tweets eines Nutzers folgen. Ferner ermöglicht
          Twitter über Hashtags, Verlinkungen oder Retweets die Ansprache eines
          breiten Publikums.
        </p>
        <p>
          Betreibergesellschaft von Twitter ist die Twitter, Inc., 1355 Market
          Street, Suite 900, San Francisco, CA 94103, USA.
        </p>
        <p>
          Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die
          durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
          welcher eine Twitter-Komponente (Twitter-Button) integriert wurde,
          wird der Internetbrowser auf dem informationstechnologischen System
          der betroffenen Person automatisch durch die jeweilige
          Twitter-Komponente veranlasst, eine Darstellung der entsprechenden
          Twitter-Komponente von Twitter herunterzuladen. Weitere Informationen
          zu den Twitter-Buttons sind unter
          https://about.twitter.com/de/resources/buttons abrufbar. Im Rahmen
          dieses technischen Verfahrens erhält Twitter Kenntnis darüber, welche
          konkrete Unterseite unserer Internetseite durch die betroffene Person
          besucht wird. Zweck der Integration der Twitter-Komponente ist es,
          unseren Nutzern eine Weiterverbreitung der Inhalte diese Internetseite
          zu ermöglichen, diese Internetseite in der digitalen Welt bekannt zu
          machen und unsere Besucherzahlen zu erhöhen.
        </p>
        <p>
          Sofern die betroffene Person gleichzeitig bei Twitter eingeloggt ist,
          erkennt Twitter mit jedem Aufruf unserer Internetseite durch die
          betroffene Person und während der gesamten Dauer des jeweiligen
          Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
          unserer Internetseite die betroffene Person besucht. Diese
          Informationen werden durch die Twitter-Komponente gesammelt und durch
          Twitter dem jeweiligen Twitter-Account der betroffenen Person
          zugeordnet. Betätigt die betroffene Person einen der auf unserer
          Internetseite integrierten Twitter-Buttons, werden die damit
          übertragenen Daten und Informationen dem persönlichen
          Twitter-Benutzerkonto der betroffenen Person zugeordnet und von
          Twitter gespeichert und verarbeitet.
        </p>
        <p>
          Twitter erhält über die Twitter-Komponente immer dann eine Information
          darüber, dass die betroffene Person unsere Internetseite besucht hat,
          wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer
          Internetseite gleichzeitig bei Twitter eingeloggt ist; dies findet
          unabhängig davon statt, ob die betroffene Person die
          Twitter-Komponente anklickt oder nicht. Ist eine derartige
          Übermittlung dieser Informationen an Twitter erhält über die
          Twitter-Komponente immer dann eine Information darüber, dass die
          betroffene Person unsere Internetseite besucht hat, wenn die
          betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite
          gleichzeitig bei Twitter eingeloggt ist; dies findet unabhängig davon
          statt, ob die betroffene Person die Twitter-Komponente anklickt oder
          nicht. Ist eine derartige Übermittlung dieser Informationen an Twitter
          von der betroffenen Person nicht gewollt, kann diese die Übermittlung
          dadurch verhindern, dass sie sich vor einem Aufruf unserer
          Internetseite aus ihrem Twitter-Account ausloggt.Twitter von der
          betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
          verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus
          ihrem Twitter-Account ausloggt.
        </p>
        <p>
          Die geltenden Datenschutzbestimmungen von Twitter sind unter
          https://twitter.com/privacy?lang=de abrufbar.
        </p>

        <h2 className="font-semibold">
          32. Datenschutzbestimmungen zu Einsatz und Verwendung von YouTube
        </h2>
        <p>
          Der für die Verarbeitung Verantwortliche kann auf dieser Internetseite
          Komponenten von YouTube integrieren. YouTube ist ein
          Internet-Videoportal, dass Video-Publishern das kostenlose Einstellen
          von Videoclips und anderen Nutzern die ebenfalls kostenfreie
          Betrachtung, Bewertung und Kommentierung dieser ermöglicht. YouTube
          gestattet die Publikation aller Arten von Videos, weshalb sowohl
          komplette Film- und Fernsehsendungen, aber auch Musikvideos, Trailer
          oder von Nutzern selbst angefertigte Videos über das Internetportal
          abrufbar sind.
        </p>
        <p>
          Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry
          Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer
          Tochtergesellschaft der Google Inc., 1600 Amphitheatre Pkwy, Mountain
          View, CA 94043-1351, USA.
        </p>
        <p>
          Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die
          durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
          welcher eine YouTube-Komponente (YouTube-Video) integriert wurde, wird
          der Internetbrowser auf dem informationstechnologischen System der
          betroffenen Person automatisch durch die jeweilige YouTube-Komponente
          veranlasst, eine Darstellung der entsprechenden YouTube-Komponente von
          YouTube herunterzuladen. Weitere Informationen zu YouTube können unter
          https://www.youtube.com/yt/about/de/ abgerufen werden. Im Rahmen
          dieses technischen Verfahrens erhalten YouTube und Google Kenntnis
          darüber, welche konkrete Unterseite unserer Internetseite durch die
          betroffene Person besucht wird.
        </p>
        <p>
          Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist,
          erkennt YouTube mit dem Aufruf einer Unterseite, die ein YouTube-Video
          enthält, welche konkrete Unterseite unserer Internetseite die
          betroffene Person besucht. Diese Informationen werden durch YouTube
          und Google gesammelt und dem jeweiligen YouTube-Account der
          betroffenen Person zugeordnet.
        </p>
        <p>
          YouTube und Google erhalten über die YouTube-Komponente immer dann
          eine Information darüber, dass die betroffene Person unsere
          Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt
          des Aufrufs unserer Internetseite gleichzeitig bei YouTube eingeloggt
          ist; dies findet unabhängig davon statt, ob die betroffene Person ein
          YouTube-Video anklickt oder nicht. Ist eine derartige Übermittlung
          dieser Informationen an YouTube und Google von der betroffenen Person
          nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
          sie sich vor einem Aufruf unserer Internetseite aus ihrem
          YouTube-Account ausloggt.
        </p>
      </div>
      <Footer />
    </div>
  );
}
