import SectionWrapper from "../components/SectionWrapper";

export default function LandingPage() {
  return (
    <>
      <SectionWrapper>
        <div className="md:w-1/2 shadow-lg bg-seas-dark md:bg-gradient-to-br from-white dark:from-base-100 to-black/50 dark:to-base-300">
          <div className="flex flex-col md:h-[calc(100vh-10rem)] justify-center mt-12 md:mt-0">
            <h1 className="px-4 text-center text-6xl font-semibold">
              <span className="font-bold text-seas-green">SEAS</span>
            </h1>
            <small className="text-center font-light -mt-2 mb-1">
              Selbsteinteilungs- & Administrationssoftware
            </small>
            <h2 className="text-center text-3xl font-semibold">
              - einfach automatisieren
            </h2>
            <div className="flex w-full justify-center items-center mt-6 gap-2 flex-wrap">
              <a
                href="https://apps.apple.com/de/app/seas-kirchengemeinde/id6683308883?itscg=30200&itsct=apps_box_badge&mttnsubad=6683308883"
                className="inline-block"
              >
                <img
                  src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/de-de?releaseDate=1729468800"
                  alt="Download on the App Store"
                  className="h-[3rem] align-middle object-contain"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=de.craftery.seasfrontend&hl=de&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Jetzt bei Google Play"
                  className="h-[3.95rem]"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-grow flex-col items-center justify-center md:w-1/2 bg-seas-dark md:min-h-[91vh] md:shadow-lg pt-12 md:pt-0">
          <div className="mb-12 mt-4 flex flex-wrap items-center justify-center gap-2 md:gap-4 px-4">
            <div>
              <h2 className="text-xl font-semibold">
                Pläne für Musik und mehr
              </h2>
              <ul className="list-disc pl-4">
                <li>Kinderleichte Organisation</li>
                <li>Beliebig erweiterbar</li>
                <li>Selbsteinteilung mit 1 Klick</li>
              </ul>
            </div>
            <img
              src={"/img/seas-screenshot-1.webp"}
              alt={"Landing Page App Screenshot"}
              className="w-60 md:w-40 lg:w-52 rounded-lg"
            />
          </div>
        </div>
      </SectionWrapper>
    </>
  );
}
