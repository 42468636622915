import React from "react";

export default function FreeMusicPricing() {
  return (
    <div
      className="flex justify-center gap-4 pt-16 md:pt-24 px-4 md:px-24"
      id="pricing"
    >
      <div
        className="sm:w-full max-w-3xl rounded-xl flex items-center flex-row"
        style={{ boxShadow: "0 1px 6px rgba(60,64,67,.3)" }}
      >
        <img
          src={"/img/clef.svg"}
          alt={"Clef"}
          className="md:h-64 md:w-64 h-48 w-48 hidden sm:flex md:my-4 ml-4"
        />
        <div className="w-full pr-4 pl-8 pt-10 flex flex-col">
          <h2 className="text-3xl mb-4">Musik Modul</h2>
          <strong className="text-xl font-semibold">kostenlos</strong>
          <p>für alle christlichen Gemeinden</p>

          <ul className="list-disc my-6 ml-4">
            <li>Enthält Musik Einteilung & Journal</li>
            <li>Einfach automatisieren</li>
            <li>Problemlos erweiterbar</li>
          </ul>

          <a className="mb-8 mt-auto" href="/contact">
            <button className="btn bg-seas-green shadow-md text-white hover:text-gray-600">
              Kostenlos anfragen
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
