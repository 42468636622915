import React from "react";

export default function SubHeader() {
  return (
    <div className="bg-seas-yellow px-2 py-1 md:flex gap-2">
      <p>
        <span className="font-semibold">Neu:</span> Unser Musikmodul ist jetzt
        für alle christlichen Gemeinden{" "}
        <span className="font-semibold">kostenlos</span> verfügbar!
      </p>
      <a href="#pricing" className="underline font-semibold">
        Mehr erfahren
      </a>
    </div>
  );
}
