import React from "react";

import SectionWrapper from "../components/SectionWrapper";

export default function CompleteSolution() {
  return (
    <SectionWrapper className="justify-center mt-12 md:mt-24 px-4 sm:px-8 gap-8">
      <div className="flex max-w-2xl items-center flex-col my-auto">
        <div className="mx-4">
          <h2 className="text-4xl font-semibold">
            <span className="font-bold text-seas-green">SEAS</span> Gesamtlösung
          </h2>
          <p className="mt-2">
            Die maßgeschneiderte Lösung für eure Gemeinde, schnell und
            bedarfsorientiert
          </p>

          <ul className="list-disc pl-4 mt-1">
            <li>Beliebig erweiterbar</li>
            <li>Hinzufügen von Plänen in Minuten</li>
            <li>
              Verantwortlichkeiten klar geregelt, jede Einheit mit
              Moderatorenfunktion
            </li>
            <li>Einfach, intuitiv und individuell für jede Gemeinde</li>
          </ul>
        </div>
      </div>
      <div className="flex justify-center items-center mt-8 md:mt-0">
        <img
          src="/img/seas-screenshot-solution.webp"
          alt="seas-screenshot-solution"
          className="w-64 rounded-lg"
        />
      </div>
    </SectionWrapper>
  );
}
