import React from "react";

export default function SeasHelpers() {
  return (
    <div className="bg-seas-medium px-8 sm:px-12 py-12 md:py-24 flex flex-col items-center">
      <h2 className="text-4xl font-semibold text-center">
        <span className="font-bold text-seas-green">SEAS</span> Hilfsmittel
      </h2>
      <div className="mt-10 flex flex-wrap gap-8 justify-center">
        <div className="flex flex-col items-center mt-2 gap-2">
          <h3 className="text-2xl font-semibold absolute">
            Direkter Kalenderexport
          </h3>
          <img
            src="/img/seas-screenshot-calendar.png"
            alt="seas-screenshot-calendar"
            className="w-48 lg:w-52 rounded-lg mt-[4.5rem] xs:mt-10"
          />
          <p>einfach informieren</p>
        </div>
        <div className="flex flex-col items-center mt-2 gap-2">
          <h3 className="text-2xl font-semibold absolute">Drucken</h3>
          <img
            src="/img/seas-screenshot-print.png"
            alt="seas-screenshot-print"
            className="w-48 lg:w-52 rounded-lg mt-10"
          />
          <p>einfach aufbereiten</p>
        </div>
        <div className="flex flex-col items-center mt-2 gap-2">
          <h3 className="text-2xl font-semibold absolute">
            Voll automatisiert
          </h3>
          <img
            src="/img/seas-screenshot-event.png"
            alt="seas-screenshot-event"
            className="w-48 lg:w-52 rounded-lg mt-10"
          />
          <p>einfach verwalten</p>
        </div>
      </div>
      <div className="divider pt-0 dark:hidden sm:hidden my-12"></div>
      <div className="flex gap-4 sm:mt-12 flex-wrap sm:flex-nowrap justify-center">
        <div>
          <img
            src="/img/seas-screenshot-example.webp"
            alt="seas example screenshot"
            className="w-48 lg:w-52 rounded-lg"
          />
          <p className="text-center">einfach benutzen</p>
        </div>
        <div className="max-w-xl my-auto">
          <h3 className="text-2xl font-semibold">
            <span className="text-seas-green">SEAS</span> in Praxis - ein
            Beispiel
          </h3>
          <p className="mt-1">
            Die Gemeinde freut sich. Karin und Bernd heiraten am Samstag, den
            30.11. um 16.00 Uhr in der Kirche.
          </p>
          <p className="mt-4 font-semibold text-lg">Was ist zu tun:</p>
          <ol className="list-decimal pl-5 flex flex-col gap-1">
            <li>
              Der Admin trägt den Termin in den Einstellungen von{" "}
              <span className="text-seas-green font-semibold">SEAS</span> per
              Klick ein.
            </li>
            <li>
              Eintragen des Grundes des zusätzlichen Termins in das
              Bemerkungsfeld.
            </li>
            <li>
              Peter kennt das Hochzeitspaar persönlich und klickt auf Orgel.
            </li>
            <li>
              Diakonin Anna übernimmt den diakonischen Dienst{" "}
              <span className="underline">und</span> das Dirigat per Klick.
            </li>
            <li>
              <span className="text-seas-green font-semibold">SEAS</span> warnt
              sie wegen der Doppelfunktion aber sie weiß, dass es geht!
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
