import React from "react";

export default function CompanyGoal() {
  return (
    <div className="flex flex-col items-center my-24">
      <h1 id="idea" className="text-3xl font-semibold text-center">
        Unternehmensziele
      </h1>
      <div className="flex flex-col md:flex-row items-center justify-center mt-6 gap-10 max-w-4xl w-[88vw]">
        <p className="text-3xl">&#128154;</p>
        <div className="text-xl text-justify flex flex-col gap-4">
          <p>
            Wir sind uns der Bedeutung einer marktwirtschaftlichen Ausrichtung
            bewusst und streben mit unserem Geschäftsmodell eine nachhaltige
            Profitabilität an. Dennoch möchten wir betonen, dass die Maximierung
            von Umsatz und Gewinn nicht die zentralen Ziele unserer
            Geschäftspolitik sind. Vielmehr legen wir großen Wert auf eine
            verantwortungsvolle und ethische Unternehmensführung, die sich an
            den Bedürfnissen der Gemeinschaft orientiert.
          </p>
          <p>
            Ein zentraler Aspekt unserer Philosophie ist die soziale
            Verantwortung. Überschüsse, die wir erwirtschaften, fließen direkt
            in gemeinnützige Organisationen oder werden karitativen Zwecken
            zugeführt. Wir glauben daran, dass wirtschaftlicher Erfolg auch eine
            Verpflichtung zur Unterstützung der Gesellschaft mit sich bringt.
            Daher setzen wir uns aktiv dafür ein, positive Veränderungen in
            unserer Umgebung zu bewirken.
          </p>
          <p>
            Darüber hinaus verzichtet der geschäftsführende Gesellschafter auf
            sein Gehalt, um sicherzustellen, dass die finanziellen Mittel
            unseres Unternehmens in Projekte investiert werden, die einen
            direkten Nutzen für die Gemeinschaft haben. Diese Entscheidung
            spiegelt unser Engagement wider, eine Unternehmenskultur zu fördern,
            die auf Solidarität und Gemeinwohl baut.
          </p>
          <p>
            Wir sind ständig auf der Suche nach jungen, engagierten Christen,
            die unsere Vision teilen und bereit sind, in den Bereichen
            Entwicklung, Support und Vertrieb mitzuwirken. Wir glauben, dass
            frische Ideen und neue Perspektiven entscheidend sind, um innovative
            Lösungen zu entwickeln und unsere Ziele zu erreichen. Wenn du Teil
            eines dynamischen Teams werden möchtest, freuen wir uns auf deine
            Kontaktaufnahme.
          </p>
          <p>
            Ein weiteres wichtiges Ziel unseres Unternehmens ist es, dass sich
            SEAS als die Standardlösung für die Erstellung von Plänen in
            Kirchengemeinden etabliert. Wir sind überzeugt, dass unsere
            Plattform den Verwaltungsaufwand für die Beteiligten erheblich
            reduzieren kann. Durch die Vereinfachung von Prozessen und die
            Bereitstellung effektiver Werkzeuge möchten wir es den
            Kirchengemeinden ermöglichen, sich auf das Wesentliche zu
            konzentrieren: die Gemeinschaft und die spirituelle Entwicklung
            ihrer Mitglieder.
          </p>
          <p>
            Wir sind stolz darauf, einen Beitrag zur Stärkung der kirchlichen
            Gemeinschaften zu leisten und freuen uns darauf, gemeinsam mit
            unseren Partnern und Mitarbeitern eine positive Zukunft zu
            gestalten.
          </p>
        </div>
      </div>
    </div>
  );
}
