export default function UnderConstruction() {
  return (
    <div className="mx-4 mt-8">
      <h2 className="text-2xl font-semibold">Diese Seite ist noch im Bau..</h2>
      <p
        className="-mb-2 mt-4 text-6xl w-min cursor-pointer"
        onClick={() => {
          document.body.setAttribute("data-mode", "dark");
          document.body.setAttribute("data-theme", "dark");
        }}
      >
        🚧
      </p>
    </div>
  );
}
