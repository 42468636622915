import React from "react";

export default function HelmutHaase() {
  return (
    <div className="flex flex-col items-center">
      <h1 id="idea" className="text-3xl font-semibold text-center mt-8">
        Helmut Haase
      </h1>

      {/* <p className="max-w-4xl w-[88vw] text-lg mt-6">
        Nach dem Studium Mathematik und BWL in Hannover mit Abschluss Dipl.
        Mathematiker Anstellung in verschiedenen Softwareunternehmen mit dem
        Schwerpunkt „neue Technologien“ und „Automatisierungsverfahren“
      </p> */}
      <p className="max-w-4xl w-[88vw] text-lg mt-6">
        Als Mathematiker tätig in der Datenverarbeitung mit den Schwerpunkten
        „Automatisierung“ von Prozessen und „Neue Technologien“.
      </p>

      <table className="h-12 max-w-4xl w-[88vw] mt-4 table">
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="text-lg">
          {/* <tr>
            <td>1984</td>
            <td>
              Automatisierung von Software-Test-Verfahren mithilfe eines
              Dictionary
            </td>
          </tr>
          <tr>
            <td>1987</td>
            <td>
              Erstes Datenbank Tool zur Kontrolle und Analyse (DBC) für
              hierarchische (IMS) und relationale Datenbanken (DB2) auf den
              Weltmarkt gebracht.
            </td>
          </tr>
          <tr>
            <td>1991-2005</td>
            <td>
              Erste Lösung (SAM) zur Automatisierung der Administrationsprozesse
              von unterschiedlichen Access-Control-Systemen als Standardsoftware
              initialisiert. Diese Technologie ist heute noch bekannt als
              Identity-Management und Provisioning-Software. Nach erfolgreicher
              Markteinführung bei den größten europäischen Banken und
              Versicherungen, folgte die Übernahme der weltweiten Verantwortung
              in einer als Aktiengesellschaft geführten Unternehmensberatung.
            </td>
          </tr>
          <tr>
            <td>ab 2005</td>
            <td>Lehrer für Mathematik und Informatik</td>
          </tr> */}
          <tr>
            <td></td>
            <td>
              Automatisierung von Software-Testverfahren in der
              Qualitätssicherung
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              Automatisierung der Wartungs-und Analyseprozesse für
              hierarchische- (IMS) und relationale Datenbanken (DB2) durch die
              erste verfügbare Standardsoftware (DBC) auf den Weltmarkt mit
              Marktanteilen in Europa größer als 40% etabliert.
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              Automatisierung der Administrationsprozesse für unternehmensweite
              Zugriffsschutzsysteme initiiert und erfolgreich als erste
              unternehmensweite Standardsoftware - heute bekannt als Identity
              Managemnt und Provisioning - auf den Weltmarkt gebracht.
            </td>
          </tr>
        </tbody>
      </table>
      <p className="max-w-4xl w-[88vw] mt-6">
        <a
          href="https://www.linkedin.com/in/helmut-haase-7531b013a/"
          className="text-lg text-seas-green font-semibold underline"
        >
          LinkedIn - Helmut Haase
        </a>
      </p>
    </div>
  );
}
